import { Box, Grid, Typography, Backdrop, CircularProgress, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { GetBlogData } from "../../Services/Api";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from "react-router-dom";
import axios from "axios";

export const Blog = () => {
   
    const [blog, setblog] = useState([]);
    const [blog2, setblog2] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        const Data = async () => {
            const response = await GetBlogData();

            setblog(response.data);

          
           
        }
        Data();
    }, [])
    blog.reverse();
    return (
        <Box>
            <Box className="About-Bg blog-bg" style={{ marginTop: 0 }}>
                <h1 >Blogs</h1>
                <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page" >Home</a> > <a href="/Blog" title = "Blog page">Blogs</a></Typography>
            </Box>
            <Box className="Card-container">
                <Grid container >
                    {
                        blog.map(data => (
                            <Grid item lg={3} xl={3} md={4} sm={4} xs={12} >
                                <Box className="Card-box" style={{overflow:"hidden"}} >
                                     <Box style={{  height:"65vh", width: "100%", marginBottom: 10 , position:"relative" }}  >
                                        <Box style={{padding:0 , width:"100%" , height:"30vh", margin:""}}>
                                            <img src={`https://svvclinic.com/uploads/${data.Thumbnail}`} style={{ width: "100%" , borderRadius:"10px 10px 0 0 ", height:"100%" , margin:"auto"}} />
                                            
                                        </Box>
                                        <Box style={{padding:20}}>
                                            {/* <Box style={{display:"flex" , flexItems:"center" , gap:30 , flexWrap:"wrap"}}>
                                        <div style={{display:"flex" , flexItems:"center"}}><AccessTimeIcon/> <span>{data.Date}</span></div>
                                        <div style={{display:"flex" , flexItems:"center"}}><AccessTimeIcon/><span>{data.Time}</span></div>
                                        </Box> */}
                                            <Box>
                                                <Typography style={{ fontWeight: 700, 
                                              fontSize : "1.3rem" , 
                                              marginTop: 5 , 
                                              fontWeight:"bold",
                                              textAlign:"left" ,
                                              overflow: "hidden",
                                              display: "-webkit-Box",
                                              WebkitBoxOrient: "vertical",
                                              WebkitLineClamp: 1,}}>{data.Tittle}</Typography>
                                                
                                            </Box>
                                            <Box>

                                        
                                            <Typography
                                                style={{
                                                    overflow: "hidden",
                                                    display: "-webkit-Box",
                                                    WebkitBoxOrient: "vertical",
                                                    WebkitLineClamp: 3,
                                                    textAlign:"left",
                                                    marginTop:7,
                                                    marginBottom:10
                                                }}>
                                                {data.Description}
                                            </Typography>
                                            </Box>
                                            <Box style={{position:"absolute" , bottom:0 , marginBottom:10}}>
                                            <Link to={`/blog/${data.Route}`} style={{ textDecoration: "none", color: "#666" }} >
                                                  <Button variant="contained" style={{background:"#18AD96" , textTransform:"capitalize" }}>Read More</Button>
                                                  </Link>
                                            </Box>
                                        </Box>
                                    </Box>
                                 
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    )
}