import { Box } from "@mui/material"
 
import { Main } from "./Main"
import { About } from "./About"
import { Patients } from "./Our-patients"
import { OurServices } from "./OurServices"
import { Testimonial } from "./PatientTestimonial"
import { useEffect } from "react"

export const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <Box>
            <Box>
                <Main/>
                <About/>
                <Patients/>
                <OurServices/>
                <Testimonial/>
            </Box>
        </Box>
    )
}