import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GetAppointment } from "../../Services/Api";
import { Box } from '@mui/material';


export default function DataTable() {
  const [Data, setData] = React.useState([]);
  React.useEffect(() => {
    const AppointmentData = async () => {
      const response = await GetAppointment();
      const fetchedData = response.data.reverse();
      if (fetchedData) {
        const dataWithId = fetchedData.map((item, index) => ({
          Sr_no: index + 1, // Generate a unique ID (using index here for simplicity)
          ...item
        }));
        setData(dataWithId);
      }
    }
    AppointmentData();
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const columns = [
    { field: 'Sr_no', headerName: 'SR No.', width: 70 },
    { field: 'Name', headerName: 'name', width: 130 },
    { field: 'Email', headerName: 'email', width: 180 },
    {
      field: 'Mobile',
      headerName: 'Phone Number',
      type: 'number',
      width: 130,
    },
    {
      field: 'Subject',
      headerName: 'Subject',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
    },
    {
      field: 'Message',
      headerName: 'Description',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,

    }
    ,
    {
      field: 'Date',
      headerName: 'Date',
      sortable: true,
      width: 160,

    }
    ,
    {
      field: 'Time',
      headerName: 'Time',

      sortable: true,
      width: 160,

    }
  ];


  return (
    <div className="Table-container">
      <Box>

        <DataGrid
          style={{ height:"90vh" ,   margin:'150px 20px 20px 20px !important'}}
          rows={Data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
          checkboxSelection
        />
      </Box>
    </div>
  );
}