import { Box } from "@mui/material";
import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

export const Patients = () => {
    const [CountOn, setCountOn] = useState(false)
    return (
        <Box>
            <ScrollTrigger onEnter={() => setCountOn(true)}   >
                <Box>
                    <Box className="Patient-Container">
                        <Box className="Patient-Data">
                            <p>{CountOn && <CountUp start={0} end={300000} duration={2} delay={0} />}+</p>
                            <h3>Happy Patients</h3>
                        </Box>

                        <Box className="Patient-Data">
                            <p>{CountOn && <CountUp start={0} end={1500} duration={2} delay={0} />}+</p>
                            <h3>Procedures</h3>
                        </Box>

                        <Box className="Patient-Data">
                            <p>{CountOn && <CountUp start={0} end={30} duration={2} delay={0} />}+</p>
                            <h3>Years of Experience</h3>
                        </Box>
                    </Box>
                </Box>
            </ScrollTrigger>
        </Box>
    )
}