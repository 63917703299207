import { Box, Button } from "@mui/material";
import { useState } from "react";
import ReactPlayer from "react-player";
import { VideoPost } from "../../Services/Api";
import axios from "axios";
import moment from "moment";


export const PostVideo = () => {
    const [VideoLink, setLink] = useState({URL:""});
    const Date = moment().format('MMMM Do YYYY');
    const Time = moment().format('h:mm:ss a')
    const LinkVideo = (event) => {
        setLink({...VideoLink , URL : event.target.value});
    }

    const PostVideo = async () => {
        const Data = new FormData();
        Data.append('Link',VideoLink.URL);
        Data.append('Date',Date);
        Data.append('Time',Time);
        const response = await VideoPost(Data);
      
      
    }
    return (
        <Box>
            <Box style={{ padding: 20 }}>
                <input type="text" id="video" style={{ width: "80%", height: "40px", padding: 10, boxSizing: "border-box" }} placeholder="Paste Youtube Video Link" onChange={event => LinkVideo(event)} />
                
                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url={VideoLink.URL}
                        width='50%'
                        height='300px'
                        style={{ margin: 20 }}
                    />
                </div>
            </Box>
            <Box style={{textAlign : "center" , margin: 40}}>
                <Button variant="contained" className="BlogButton" onClick={PostVideo}>Post</Button>
                </Box>
        </Box>
    )
}