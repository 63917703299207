import { Box, Typography } from "@mui/material" ;
import { Carousels } from "./Carousel";

export const Testimonial = () => {
    return(
        <Box>
            <Box>
                <Box style={{ textAlign:"center"}}>
                    <h1 style={{color : "#18AD96" }}>Patient Testimonials</h1>
                    <Typography>We have hundreds of satisfied patients who have successfully returned to their normal life with our treatment. A review from our clients gives us power and support to move forward.</Typography>
                </Box>
                <Carousels/>
            </Box>
        </Box>
    )
}