
import { useParams } from "react-router-dom";
import { Box, Button } from "@mui/material"
import { useEffect } from "react";
import React, { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import QuillToggleFullscreenButton from 'quill-toggle-fullscreen-button';
import { UpdatePostData } from "../../Services/Api";
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/toggleFullscreen', QuillToggleFullscreenButton);
const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6] }], [{ font: ['Poppins'] }],
        [{ size: [] }],
        ['code-block'],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean'],

    ],
    toggleFullscreen: true,
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const formats = [
    'header',
    'font',
    'size',
    'code-block',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
];


export const EditBlogData = () => {
    const { id } = useParams();
    const [BlogData, setBlogData] = useState({ _id: id })
    const [file, setFile] = useState("");
    useEffect(() => {
        
        const Data = async () => {
            const data = new FormData()
            data.append("route", id)
            const response = await axios.post("https://svvclinic.com/FindBlog.php", data);
            setBlogData(response.data[0]);
        }
        Data();
        
    }, []);

    const handleEdit = (e) => {
        if (e.target.name == "Thumbnail") {
            setBlogData({ ...BlogData, [e.target.name]: e.target.files[0] });
        } else {
            setBlogData({ ...BlogData, [e.target.name]: e.target.value });
        }


    }

    const SaveEditorData = (newContent) => {
        setBlogData({ ...BlogData, Content: newContent });
    }

    const UpdateData = async () => {
        const data = new FormData()
        data.append("route2", id);
        data.append("route", BlogData.Route);
        data.append("Tittle", BlogData.Tittle);
        data.append("Description", BlogData.Description);
        data.append("Content", BlogData.Content);
        data.append("Thumbnail", BlogData.Thumbnail);
        if (typeof (BlogData.Thumbnail) == "object") {
            const response = await axios.post("https://svvclinic.com/updateBlogimage.php", data);
            if (response.status == 200) {
                toast.success("Blog Post Successfull Updated");
                BlogData.contents = "";
                BlogData.tittle = "";
                BlogData.image = "";
                BlogData.description = "";
                BlogData.meta_description="";
                BlogData.meta_description=""

                // window.location = "/Blog";
            } else {
                toast.error("Please check your internet connection....")
            }

        } else {
            try {
                const response = await UpdatePostData(data);

                if (response.status == 200) {
                    toast.success("Blog Post Successfull Updated");
                    BlogData.contents = "";
                    BlogData.tittle = "";
                    BlogData.image = "";
                    BlogData.description = "";
                    // window.location = "/Blog";
                }
            }
            catch (error) {
                toast.error("This Route Already Exist Enter Another Route ")

            }
        }



    }



    return (
        <Box>
            <Box className="editPost" >
                <Box>
                    <input type="Textarea" placeholder="Title" name="Tittle" value={BlogData.Tittle} onChange={handleEdit} />
                    <input type="Textarea" placeholder="Route" name="Route" value={BlogData.Route} onChange={handleEdit} />
                    <input type="Textarea" placeholder="Meta_title" name="meta_tittle" value={BlogData.meta_title} onChange={handleEdit} />
                    <textarea rows={7} cols={50} placeholder="Meta Description" name="meta_description" value={BlogData.meta_description} onChange={handleEdit} />
                    <Box className="Thumbnail">
                        <Box style={{ width: "100%" }}>
                            <input type='text' placeholder='Select Thumbnail' readOnly value={BlogData.Thumbnail} style={{ color: "#757575", fontWeight: 600, fontSize: 16 }} />
                        </Box>
                        <Box className="Thumbnail-Lable">
                            <label htmlFor='thumbnail'>

                                <AddIcon fontSize="medium" color="action" style={{ border: "1px solid black", background: "#18AD96", borderRadius: "50%", }} />
                            </label>
                        </Box>
                        <input type="file" accept="image/*" placeholder="Thumbnail Url " id="thumbnail" name="Thumbnail" onChange={handleEdit} style={{ display: "none" }} />
                    </Box>
                    <textarea rows={7} cols={50} placeholder="Describe some Words About Your Blog at least 30 words" name="Description" value={BlogData.Description} onChange={handleEdit} />


                    <Box style={{ height: "40vh" }}>
                        <ReactQuill
                            style={{ height: "100%", }}
                            className="editior" theme="snow" name="Content" value={BlogData.Content} onChange={setValue => SaveEditorData(setValue)}
                            modules={modules}
                            formats={formats}
                        />
                    </Box>
                    <Box style={{ textAlign: "center" }}>
                        <Button className="BlogButton" variant="contained" onClick={UpdateData} style={{ marginTop: 70 }}>Update Blog</Button>
                    </Box>
                </Box>

                <ToastContainer />
            </Box>
        </Box>
    )
}