import { Box, Typography } from "@mui/material"

export const About = () => {
    return (
        <Box>
            
            <Box className="OueServices" style={{ display: "flex", gap: 20 }}>
                <Box className="About-Containers" style={{ }}>
                    <img src="About-Dr.webp" alt="about-logo" style={{ width: "100%" }} />
                </Box>
                <Box className="About-left" style={{ width: "100%" , padding:10 }}>
                <Box>
                <h4>About</h4>
            </Box>
                    <Typography variant="h3">
                   <strong> Dr. Vireesha Bogireddy</strong>
                    </Typography>
                    <Typography style={{ fontSize: 18 ,  lineHeight:"28px", fontFamily:"Poppins" , color:"#333333"}}>
                    Welcome to SVV Clinic, where the ancient art of Ayurveda meets modern healthcare, creating a sanctuary for holistic well-being. Nestled in the vibrant city of Hyderabad, SVV Clinic is the manifestation of a rich legacy passed down through generations.
                    </Typography>

                    <Box style={{marginTop:20}}>
                 <h4> About the Founder</h4>
             </Box>
            
                 <Box  className="About-Wrapper">
                    
                     <Typography style={{ fontSize: 18 ,  color:"#333333"  , lineHeight:"28px" }}>
                     SVV Clinic, a beacon of healing and well-being, was founded by the visionary Dr. Nagireddy Bogireddy over 30 years ago in the picturesque town of Pen, Raigad District, Maharashtra. Driven by a passion for holistic healthcare, Dr. Nagireddy embarked on a journey that would leave an indelible mark on the field of Ayurvedic medicine.
                     </Typography>
                 </Box>
                </Box>
            </Box>

           
        </Box>
    )
}





// import { Box, Typography } from "@mui/material"

// export const About = () => {
//     return (
//         <Box style={{overflow:"hidden"}}>
//             <Box style={{marginTop:20}}>
//                 <h1   className="About-Section"> About SVV Clinic <hr /></h1>
//             </Box>
            
//                 <Box style={{ width: "70%" ,  margin:"auto" , padding:10}} className="About-Wrapper">
//                     <Typography style={{ fontSize: 17 , padding : 10 , color:"#666666"  , lineHeight:"28px"}}>
//                     Welcome to SVV Clinic, where the ancient art of Ayurveda meets modern healthcare, creating a sanctuary for holistic well-being. Nestled in the vibrant city of Hyderabad, SVV Clinic is the manifestation of a rich legacy passed down through generations.
//                     </Typography>
//                 </Box>


//                 <Box style={{marginTop:20}}>
//                 <h1 className="About-Section"> Our Philosophy<hr /></h1>
//             </Box>
            
//                 <Box style={{ width: "70%" ,  margin:"auto" , padding:10 }}>
                    
//                     <Typography style={{ fontSize: 18 , padding : 10 }}>
//                     At the heart of SVV Clinic's philosophy is the belief in the body's innate ability to heal when provided with the right guidance and natural remedies. We blend traditional Ayurvedic practices with contemporary medical knowledge, offering a comprehensive and personalized approach to health.
//                     </Typography>
//                 </Box>


//                 <Box style={{marginTop:20}}>
//                 <h1 className="About-Section"> Tradition Meets Innovation <hr /></h1>
//             </Box>
            
//                 <Box style={{ width: "70%" ,  margin:"auto" , padding:10 }}>
                    
//                     <Typography style={{ fontSize: 18 , padding : 10 }}>
//                     SVV Clinic is not just a healthcare facility; it is a harmonious blend of tradition and innovation. Our treatments are rooted in the time-tested principles of Ayurveda, while our methods leverage the advancements of modern medicine. This integration allows us to provide effective and sustainable solutions for a range of health concerns.


//                     </Typography>
//                 </Box>


//                 <Box style={{marginTop:20}}>
//                 <h1 className="About-Section">Holistic Healing Spaces <hr /></h1>
//             </Box>
            
//                 <Box style={{ width: "70%" ,  margin:"auto" , padding:10 }}>
                    
//                     <Typography style={{ fontSize: 18 , padding : 10 }}>
//                     Step into our clinic, and you'll find an environment designed to soothe the senses and promote healing. From the calming interiors to the welcoming staff, every element is curated to make your wellness journey comfortable and rejuvenating.

//                     </Typography>
//                 </Box>

//                 <Box style={{marginTop:20}}>
//                 <h1 className="About-Section"> About the Founder <hr /></h1>
//             </Box>
            
//                 <Box style={{ width: "70%" ,  margin:"auto" , padding:10 }}  className="About-Wrapper">
                    
//                     <Typography style={{ fontSize: 17 , padding : 10 , color:"#666666"  , lineHeight:"28px" }}>
//                     SVV Clinic, a beacon of healing and well-being, was founded by the visionary Dr. Nagireddy Bogireddy over 30 years ago in the picturesque town of Pen, Raigad District, Maharashtra. Driven by a passion for holistic healthcare, Dr. Nagireddy embarked on a journey that would leave an indelible mark on the field of Ayurvedic medicine.
//                     </Typography>
//                 </Box>


//         </Box>
//     )
// }