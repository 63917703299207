import { Box, Grid, Typography } from "@mui/material"
import PhoneIcon from '@mui/icons-material/Phone';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
const Footer = () => {
    return (
        <Box>
            <Box className="Footer-container">
                <Grid container rowSpacing={6} columnSpacing={6} >
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Box >
                            <Typography style={{ fontWeight: 400, fontSize: 17 , fontFamily:"Poppins" }}>
                            Dr. Vireesha Bogireddy - An Expert in Anorectal Care Welcome to the SVV Clinic website, Dr. Vireesha Bogireddy, a renowned specialist in anorectal problems and a master of Kshar Sutra karma procedures for over 30 years.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Box style={{ marginLeft: '6%' }}>
                            <Typography >Quick Links</Typography>
                            <div>
                            <div  > <a href="/" className="footer-links"> <ChevronRightIcon /> Home </a></div>
                            
                            <div > <a className="footer-links" href="/About"> <ChevronRightIcon /> About Doctor </a></div>
                            <div > <a className="footer-links" href="/Blog"> <ChevronRightIcon /> Blogs </a></div>
                            <div > <a className="footer-links" href="/Contact"> <ChevronRightIcon />  Contact Us </a></div>
                            </div>
                            
                        </Box>
                    </Grid>

                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Box>
                            <Typography>Address :</Typography>
                            <div>
                                <div style={{ fontWeight: 500, fontSize: 16 }}>
                                    <a style={{color:"#fff"}} >Ground floor, H.No: 2, 56/9, Meridian School Rd, beside Mallikarjuna Super Market, opp. Vegetable Market, Ayyappa Society, Hyderabad, Telangana 500081.									
                                    </a>
                                </div>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <a href="tel:+919518312766" className="footer-links" style={{ color: "#fff", textDecoration: "none", textAlign: "center", display: "flex", gap: 5 }}> <PhoneIcon /> +919518312766</a>
                                </div>


                            </div>
                        </Box>
                    </Grid>

                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <Box>
                            <Typography  >
                                Follow Us
                            </Typography>

                            <div style={{ display: "flex", gap:0  , padding:0 ,  width:"100%" , flexWrap:"wrap"}}>
                                <a href="https://www.instagram.com/svvclinic/" target="_blank" style={{ borderRadius: "50%" , width:"20%"}}>
                                    <img src="/Instagram-svg.svg" style={{width:"60%" ,  height:"auto"}}/>
                                </a>
                                <a href="https://www.facebook.com/people/SVV-Clinic/100093258063250/" target="_blank" style={{ borderRadius: "50%" , width:"20%"}}>
                                <img src="/facebook-svg.svg" style={{width:"60%" , height:"auto"}}/>
                                </a>
                                <a href="https://www.youtube.com/results?search_query=%40svvclinic" target="_blank" style={{ borderRadius: "50%" , width:"20%"}}>
                                <img src="/youtube-svg.svg" style={{width:"60%" , height:"auto"}}/>
                                </a>
                                
                                <a href="https://www.linkedin.com/feed/?trk=onboarding-landing" target="_blank" style={{ borderRadius: "50%" , width:"20%"}}>
                                    <img src="/linkedin-svg.svg" style={{width:"60%" , height:"auto"}}/>
                                </a>
                                
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Footer 