import { Box, Button, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Book_Appointement } from "../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDialog from "./Dialoge";
import moment from "moment";
import axios from "axios";
var Data = {
    name: "",
    email: "",
    phone:"",
    subject: "",
    msg: "",
    Date: moment().format('MMMM Do YYYY'),
    Time: moment().format('h:mm:ss a')
}

export const BookAppointment = () => {
    
    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
    var regexname = /^[a-zA-Z]+[a-zA-Z\s]*?[^0-9]$/;
    var emailregex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var msgregex = /^[a-zA-Z0-9@.]+[a-zA-Z0-9\s]*?$/;

    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });
        

    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length!=10 || UserData.phone<1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else if (!UserData.subject || !msgregex.test(!UserData.subject)) {
            toast.error("Enter Your Subject")
        }
        else {
            const data = new FormData()
            data.append("Name",UserData.name)
            data.append("Message",UserData.msg);
            data.append("Subject",UserData.subject);
            data.append("Mobile",UserData.phone);
            data.append("Email",UserData.email);
            data.append("Time",UserData.Time);
            data.append("Date",UserData.Date);
            const Response = await Book_Appointement(data); 
            console.log(Response)
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <Box style={{ marginBottom: 100 }}>
            <Box className="About-Bg blog-bg" style={{ marginTop: 0 }}>
                <h1 >Book Appointment</h1>
                <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page" >Home</a> > <a href="/Blog" title = "Book Appointment Page">Book Appointment</a></Typography>
            </Box>
            <Box>
                <Box>
                    <Box className="BookAppointment-Box" style={{ marginTop: 40 }}>
                        <h2>Book An Appointment</h2>
                        <p>Please call or email contact form and we will be happy to assist you.</p>
                        <Box className="Book-appointment-input">
                        <Grid container spacing={5}>
                            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                                <Box>
                                    <input type="text" name='name' placeholder="Your Name : " className="contact-input" onChange={Book} ></input>
                                </Box>
                            </Grid>

                            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                                <Box>
                                    <input type="text" name='email' placeholder="Your Email :" className="contact-input" onChange={Book}></input>
                                </Box>
                            </Grid>
                            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                                <Box>
                                    <input type="number" name='phone' placeholder="Your Phone : " className="contact-input" onChange={Book}></input>
                                </Box>
                            </Grid>

                            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                                <Box>
                                    <input type="text" name='subject' placeholder="Subject :" className="contact-input" onChange={Book}></input>
                                </Box>
                            </Grid>

                            <Grid item md={12} lg={12} xl={12} sm={12} xs={12}>
                                <Box>
                                    <textarea type="text" name='msg' placeholder="Message (optional)"  onChange={Book} rows={4} cols={30} ></textarea>
                                </Box>
                            </Grid>
                        </Grid>

                        <Button className="Book-appointment-btn" onClick={Submit} style={{ marginTop: 20 }}>Send Message</Button>
                    </Box>
                </Box>
            </Box>
            </Box>
            <ToastContainer />
            <AlertDialog open={open} setOpen={setOpen} />
        </Box>
    )
}
