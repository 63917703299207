import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

export default function PositionedMenu({ anchorEl, setAnchorEl }) {

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(true);
  
   
  };


  return (
    <div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{  padding:10 , marginTop: 40, zIndex: 3200   }} >

 <MenuItem onClick={handleClose} className="Treament-items" style={{background:"white"}} ><Link to="/treatment/piles">Piles Treatment</Link></MenuItem>
        <MenuItem onClick={handleClose} className="Treament-items"><Link to="/treatment/fissure"> Fissure Treatment</Link></MenuItem>
        <MenuItem onClick={handleClose} className="Treament-items"><Link to="/treatment/obsteric">Obsteric Fistula</Link></MenuItem> 
         <MenuItem onClick={handleClose} className="Treament-items"><Link to="/treatment/fistula">Anal Fistula</Link></MenuItem> 
       <MenuItem onClick={handleClose} className="Treament-items"> <Link to="/treatment/constipation">Constipation Treatment</Link></MenuItem>
       <MenuItem onClick={handleClose} className="Treament-items"> <Link to="/treatment/Kshar-Sutra">Kshar Sutra Karma (Procedure)</Link></MenuItem> 
      </Menu>
    </div>
  );
}

export const AdminMenu = ({ anchorE2, setAnchorE2 }) => {
  const open = Boolean(anchorE2);
  const handleClose = () => {
    setAnchorE2(null)
  };
  const LogOut = () => {
    var remove= localStorage.removeItem('login');
    setAnchorE2(null);
    window.location.href="/admin"
  }
  return (
    <div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorE2}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: 50, marginTop: 40, zIndex: 3200 }}>
        <Link to="/admindashboard"  ><MenuItem onClick={handleClose}>Admin Dashboard</MenuItem></Link>
        <Link to="/Total-appointements"><MenuItem onClick={handleClose}>Appointements</MenuItem></Link>
        <Link to="/Today-appointements"><MenuItem onClick={handleClose}>Today Appointemet</MenuItem></Link>
        <Link to="/post-blog"><MenuItem onClick={handleClose}>Post Blog</MenuItem></Link>
        <Link to="/Total-blogs"><MenuItem onClick={handleClose}>Total Blogs</MenuItem></Link>
        <Link to="/post-video"><MenuItem onClick={handleClose}>Post Youtube Video</MenuItem></Link>
        <MenuItem onClick={LogOut}>Log Out </MenuItem>
        
      </Menu>
    </div>
  );
}