import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Protected = (props) => {

    const {Component} = props ;
    const Navigate = useNavigate() 

    useEffect(() => {
        
        let login = localStorage.getItem("login") ;
        if(!login){
            Navigate("/admin")
        }
    })

    return(
        <Box>
        <Component/>
        </Box>
    )
}