import { Box, Typography, Grid, Button } from "@mui/material"
import { useEffect, useState } from "react"
import moment from "moment";
import { Book_Appointement } from "../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDialog from "../Book-Appointment/Dialoge";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

var Data = {
    name: "",
    email: "",
    phone: "",
    msg: "",
    Date: moment().format('MMMM Do YYYY'),
    Time: moment().format(' h:mm:ss a')
}

var regexname = /^[a-zA-Z]+[a-zA-Z\s]*?[^0-9]$/;
var emailregex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var msgregex = /^[a-zA-Z0-9@.]+[a-zA-Z0-9\s]*?$/;


export const Piles = () => {



    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
    const [Show, setShow] = useState(false);
    const [Show1, setShow1] = useState(false)
    const [Show2, setShow2] = useState(false);
    const [Show3, setShow3] = useState(false);
    const [Show4, setShow4] = useState(false)

    const show = () => {
        setShow(true);
        document.getElementById("Q").style.display = "block";


    }

    const handleshow = () => {
        setShow(false);
        document.getElementById("Q").style.display = "none";
    }

    const show1 = () => {
        setShow1(true);
        document.getElementById("Q1").style.display = "block";
    }

    const handleshow1 = () => {
        setShow1(false);
        document.getElementById("Q1").style.display = "none";
    }

    const show2 = () => {
        setShow2(true);
        document.getElementById("Q2").style.display = "block";
    }

    const handleshow2 = () => {
        setShow2(false);
        document.getElementById("Q2").style.display = "none";
    }

    const show3 = () => {
        setShow3(true);
        document.getElementById("Q3").style.display = "block";
    }

    const handleshow3 = () => {
        setShow3(false);
        document.getElementById("Q3").style.display = "none";
    }



    const show4 = () => {
        setShow4(true);
        document.getElementById("Q4").style.display = "block";

    }
    const handleshow4 = () => {
        setShow4(false);
        document.getElementById("Q4").style.display = "none";
    }

    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });

    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length != 10 || UserData.phone < 1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else {

            const data = new FormData()
            data.append("Name", UserData.name)
            data.append("Message", UserData.msg);
            data.append("Subject", UserData.subject);
            data.append("Mobile", UserData.phone);
            data.append("Email", UserData.email);
            data.append("Time", UserData.Time);
            data.append("Date", UserData.Date);
            const Response = await Book_Appointement(data);
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }

    const handleCloseData = (ShowData, setShowData, id) => {
        {
            if (!ShowData) { setShowData(true); document.getElementById(id).style.display = 'block' }
            else {
                setShowData(false); document.getElementById(id).style.display = 'none';

            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("MetaTitle").setAttribute("content", "What Are Piles ?");
        document.getElementById("MetaDescription").setAttribute("content", "Piles, also known as hemorrhoids, are swollen blood vessels located in the rectum and anus. They can be internal, occurring inside the rectum, or external, developing under the skin around the anus. Piles can cause discomfort, pain, itching, and sometimes bleeding during bowel movements.");
    }, [])

    return (
        <Box>
            <Box>
                <Box className="About-Bg piles-bg" >
                    <h1 >Piles Treatment</h1>
                    <Typography variant="h5" className="Anchor "><a href="/" title="Go To Home Page" >Home</a> >   <a href="/Treatment/Piles" title="Go To Piles Page" >Piles Treatment</a></Typography>
                </Box>
                <Box className="Product-main"  >
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h1"><b>Understanding Piles : Causes, Symptoms, and Treatment</b></Typography>


                        <Typography variant="h2"><b>What Are Piles ?</b></Typography>

                        <Typography>
                            Piles also known as hemorrhoids are swollen blood vessels located in the rectum and anus. They can be internal (occurring inside the rectum) or external developing under the skin around the anus. Piles can cause discomfort, pain, itching and sometimes bleeding during bowel movements.

                        </Typography>

                        <Typography variant="h2"><b>Causes of Piles</b></Typography>

                        <Typography>Several factors contribute to the development of piles. The most common causes include:</Typography>

                        <ul>
                            <li>
                                <b>Straining during bowel movements:</b> Putting excessive pressure on the rectal area while passing stool can lead to the development of piles.


                            </li>

                            <li>
                                <b>   Chronic constipation or diarrhea: </b>Irregular bowel habits can make the passing of stool difficult or frequent contributing to the formation of piles.

                            </li>

                            <li>
                                <b>   Obesity and a sedentary lifestyle:</b> Being overweight and leading a sedentary lifestyle can increase the risk of developing piles.
                            </li>

                            <li>
                                <b>    Pregnancy and childbirth: </b> The increased pressure on the rectal area during pregnancy and the strain of childbirth can result in piles.
                            </li>

                            <li>
                                <b>    Aging: </b> As we age the tissues supporting the anal area weaken, making us more susceptible to piles.

                            </li>

                            <li>
                                <b>  Heavy lifting or prolonged sitting: </b> Jobs or activities that involve heavy lifting or prolonged sitting can strain the rectal area leading to piles.


                            </li>
                        </ul>

                        <Typography variant="h2"><b>Understanding the Symptoms</b></Typography>

                        <Typography>Recognizing the symptoms of piles is crucial for early detection and effective treatment. Some common symptoms include:</Typography>

                        <ul>
                            <li>
                                <b>   Itching, irritation or pain in the anal area: </b> Piles can cause discomfort, making it uncomfortable to sit or move.
                            </li>

                            <li>
                                <b>   Blood in the stool or on toilet paper:</b> Piles can result in bleeding during bowel movements. Blood may be visible in the stool or on the toilet paper.

                            </li>

                            <li>
                                <b>  Swelling or a lump near the anus: </b>Piles can cause a lump or swelling around the anus making it painful to touch or sit.

                            </li>

                            <li>
                                <b>  Discomfort or pain during bowel movements: </b> The presence of piles can make passing stool painful or uncomfortable.
                            </li>

                            <li>
                                <b>  Leakage of feces:  </b>In some cases piles may cause a leakage of feces which may lead to embarrassment and discomfort.

                            </li>
                        </ul>

                        <Typography variant="h2"><b>Taking Care to Avoid Piles</b></Typography>

                        <Typography>While piles can be bothersome, certain lifestyle changes can help prevent their occurrence. Here are some care tips to avoid piles:</Typography>

                        <ul>
                            <li>
                                <b>  Maintain a high-fiber diet: </b>Consuming a diet rich in fiber can promote regular bowel movements and prevent constipation reducing the risk of piles.
                            </li>

                            <li>
                                <b>   Stay hydrated: </b>Drinking an adequate amount of water daily helps keep the stool soft and facilitates smooth bowel movements.
                            </li>

                            <li>
                                <b> Engage in regular exercise: </b>Regular physical activity improves bowel function and circulation reducing the likelihood of developing piles.
                            </li>

                            <li>
                                <b> Avoid straining during bowel movements: </b>Take your time and relax while passing stool to prevent unnecessary strain on the rectal area.                            </li>

                            <li>
                                <b>  Practice good hygiene: </b>Keeping the anal area clean and dry after bowel movements can help prevent infections and irritations
                            </li>
                        </ul>
                        <Typography variant="h2"><b>Conclusion</b></Typography>
                        <Typography>
                            In conclusion Piles or Hemorrhoids are a common condition that can cause discomfort and pain. However, with a proper understanding of preventive measures and effective treatments like the Kshar Sutra procedure offered by Dr. Vireesha Bogireddy at SVV Clinic, individuals can find relief and improve their quality of life.


                        </Typography>
                        <Typography variant="h4">Frequently Asked Questions (FAQs)</Typography>

                        <Box style={{ padding: 10 }}>
                            <Box style={{ marginTop: 10 }} >
                                <Box className="FAQS" onClick={() => handleCloseData(Show, setShow, 'Q')}>
                                    <Typography variant="h2" ><b>Q1. What are the common risk factors for developing piles? </b></Typography>


                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show ? <ArrowDropUpIcon onClick={handleshow} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show} style={{ fontSize: 40 }} />}
                                    </Box >

                                </Box>
                                <Typography id="Q" style={{ display: "none" }} >Ans: Obesity, chronic constipation, pregnancy, aging and a sedentary lifestyle are common risk factors for developing piles.</Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show1, setShow1, 'Q1')}>
                                    <Typography variant="h2"><b>Q2. Can piles be treated without surgery?</b></Typography>

                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show1 ? <ArrowDropUpIcon onClick={handleshow1} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show1} style={{ fontSize: 40 }} />}
                                    </Box >

                                </Box>

                                <Typography id="Q1" style={{ display: "none" }} >Ans: Yes, piles can be effectively treated without surgery through procedures such as the Kshar Sutra technique offered by Dr. Vireesha Bogireddy at SVV Clinic.</Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show2, setShow2, 'Q2')}>
                                    <Typography variant="h2"><b>Q3. How long does the Kshar Sutra procedure take?</b></Typography>

                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show2 ? <ArrowDropUpIcon onClick={handleshow2} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show2} style={{ fontSize: 40 }} />}
                                    </Box >

                                </Box>
                                <Typography id="Q2" style={{ display: "none" }} >Ans: The duration of the Kshar Sutra procedure may vary depending on the severity of the piles. Dr. Vireesha Bogireddy will provide you with an estimate based on your specific case.</Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show3, setShow3, 'Q3')}>
                                    <Typography variant="h2"><b>Q4. Is the Kshar Sutra procedure painful?</b></Typography>

                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show3 ? <ArrowDropUpIcon onClick={handleshow3} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show3} style={{ fontSize: 40 }} />}
                                    </Box >

                                </Box>
                                <Typography id="Q3" style={{ display: "none" }} >Ans: The Kshar Sutra procedure is performed under local anesthesia, which helps minimize discomfort. Patients may experience mild discomfort during the recovery period, but pain can be managed with medications.</Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show4, setShow4, 'Q4')}>
                                    <Typography variant="h2"><b>Q5. Can lifestyle changes prevent the recurrence of piles?</b></Typography>

                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show4 ? <ArrowDropUpIcon onClick={handleshow4} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show4} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q4" style={{ display: "none" }} >Ans: Yes, adopting a healthy lifestyle that includes regular exercise, a high-fiber diet and maintaining proper hygiene can significantly reduce the chances of piles recurring.
                                </Typography>
                            </Box>



                        </Box>
                    </Box>

                    <Box className="Appointement-Box Appointment-Box-Mobile  ">
                        <Box className="Product-page-right">
                            <Box className="Product-page-right-frame">
                                <Box className="Product-page-right-wrapper">
                                    <img src="/svv clinic side image.png" />
                                    <Box className="Product-page-right-text">
                                        <h5>Dr. Vireesha </h5>
                                        <hr />
                                        <h6 style={{textTransform:'Capitalize'}}>
                                            Expert in Anorectal problems & Kshar sutra karma
                                        </h6>
                                        <h5>Contact Info </h5>
                                        <ul style={{ padding: "0px" }}>


                                            <li style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <PhoneIcon style={{ color: "#fff" }} /><a href="tel:+919518312766">+919518312766</a>
                                            </li>
                                            <li style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 10 }}>
                                                <EmailIcon style={{ color: "#fff" }} />   <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank">svvclinic@gmail.com</a>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="Product-page-right-book-appoi-container">
                            <h4>Book An Appointment</h4>
                            <Box>
                                <Box>
                                    <form>
                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Name" name="name" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Email" name="email" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Phone" type="number" name="phone" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <textarea rows={4} cols={40} aria-required={true} aria-invalid={false} name="msg" placeholder="Message (Optional)" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p style={{ textAlign: 'center' }}>
                                            <label>
                                                <span>
                                                    <Button variant="contained" onClick={Submit}>Submit</Button>
                                                </span>
                                            </label>
                                        </p>
                                    </form>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            <AlertDialog open={open} setOpen={setOpen} />
        </Box>
    )
}


// 2ND 


export const Fissure = () => {



    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
    const [Show, setShow] = useState(false);
    const [Show1, setShow1] = useState(false)
    const [Show2, setShow2] = useState(false);
    const [Show3, setShow3] = useState(false);
    const [Show4, setShow4] = useState(false)

    const show = () => {
        setShow(true);


        document.getElementById("Q").style.display = "block";


    }

    const handleshow = () => {
        setShow(false);
        document.getElementById("Q").style.display = "none";
    }

    const show1 = () => {
        setShow1(true);
        document.getElementById("Q1").style.display = "block";
    }

    const handleshow1 = () => {
        setShow1(false);
        document.getElementById("Q1").style.display = "none";
    }

    const show2 = () => {
        setShow2(true);
        document.getElementById("Q2").style.display = "block";
    }

    const handleshow2 = () => {
        setShow2(false);
        document.getElementById("Q2").style.display = "none";
    }
    const show3 = () => {
        setShow3(true);
        document.getElementById("Q3").style.display = "block";
    }
    const handleshow3 = () => {
        setShow3(false);
        document.getElementById("Q3").style.display = "none";
    }
    const show4 = () => {
        setShow4(true);
        document.getElementById("Q4").style.display = "block";

    }
    const handleshow4 = () => {
        setShow4(false);
        document.getElementById("Q4").style.display = "none";
    }

    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });

    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length != 10 || UserData.phone < 1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else {

            const data = new FormData()
            data.append("Name", UserData.name)
            data.append("Message", UserData.msg);
            data.append("Subject", UserData.subject);
            data.append("Mobile", UserData.phone);
            data.append("Email", UserData.email);
            data.append("Time", UserData.Time);
            data.append("Date", UserData.Date);
            const Response = await Book_Appointement(data);
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }
const handleCloseData = (ShowData, setShowData, id) => {
        {
            if (!ShowData) { setShowData(true); document.getElementById(id).style.display = 'block' }
            else {
                setShowData(false); document.getElementById(id).style.display = 'none';

            }
        }
    }
    useEffect(() => {

        window.scrollTo(0, 0);
        document.getElementById("MetaTitle").setAttribute("content", "What is an Anal Fissure ?");
        document.getElementById("MetaDescription").setAttribute("content", "An anal fissure is a small tear or split in the mucous membrane lining of the anus or lower rectum. It is often caused by passage of hard or large bowel movements, leading to pain and discomfort during bowel movements. Anal fissures are more common in adults and can occur both acutely and chronically.");
    }, [])
    return (
        <Box>
            <Box>
                <Box className="About-Bg fissure-bg" >
                    <h1 >Fissure Treatment</h1>
                    <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page" >Home</a> >   <a href="/Treatment/Fissure" title="Go To Fissure Treatment Page" >Fissure Treatment</a></Typography>
                </Box>
                <Box className="Product-main"  >
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h1">Understanding Anal Fissure : Causes, Symptoms, and Treatment</Typography>


                        <Typography variant="h2"><b>What is an Anal Fissure?</b></Typography>

                        <Typography>
                            An anal fissure is a small tear or split in the mucous membrane lining of the anus or lower rectum. It is often caused by passage of hard or large bowel movements, leading to pain and discomfort during bowel movements. Anal fissures are more common in adults and can occur both acutely and chronically.

                        </Typography>

                        <Typography variant="h2"><b>Causes of Anal Fissure</b></Typography>

                        <Typography>The primary causes of anal fissures include:</Typography>

                        <ul>
                            <li>
                                <b>          Hard Stool: </b> Passing hard or large stools can stretch the anal canal, causing a fissure to develop.

                            </li>

                            <li>
                                <b>          Constipation: </b>Chronic constipation, often associated with straining during bowel movements, increases the risk of developing anal fissures.

                            </li>

                            <li>
                                <b>         Diarrhea: </b> Persistent diarrhea can irritate and inflame the anal canal, making it more susceptible to fissures.
                            </li>

                            <li>
                                <b>         Anal Trauma: </b>Injury to the anal area, such as through rough wiping or anal sex, can lead to the formation of anal fissures.                            </li>

                            <li>
                                <b>         Crohn's Disease: </b>Individuals with Crohn's disease, a chronic inflammatory bowel disease, are at a higher risk of developing anal fissures.

                            </li>

                        </ul>

                        <Typography variant="h2"><b>Symptoms of Anal Fissure</b></Typography>

                        <Typography>The common symptoms of anal fissures may include:</Typography>

                        <ul>
                            <li>
                                Pain during bowel movements
                            </li>

                            <li>
                                Bright red blood on the toilet paper or in the toilet bowl                            </li>

                            <li>
                                Itching or irritation around the anus

                            </li>

                            <li>
                                A visible tear or crack in the anal tissue
                            </li>

                            <li>
                                Pain or discomfort lasting hours after a bowel movement
                            </li>
                        </ul>

                        <Typography variant="h2"><b>Diagnosing Anal Fissure</b></Typography>

                        <Typography>To diagnose an anal fissure, a healthcare professional will conduct a physical examination and review the patient's medical history. Additional diagnostic procedures may include:</Typography>

                        <ul>
                            <li>
                                <b>    Visual Examination: </b>The doctor will visually inspect the anal area to look for signs of a fissure or any other abnormalities.
                            </li>
                            <li>
                                <b>   Anoscopy: </b>This procedure involves using a small, lighted tube called an anoscope to examine the anal canal more closely.
                            </li>

                            <li>
                                <b>   Stool Sample: </b>A stool sample may be collected to rule out other conditions that may cause similar symptoms, such as infections or inflammatory bowel disease.
                            </li>

                        </ul>


                        <Typography variant="h2"><b>Treatment Options for Anal Fissure</b></Typography>

                        <Typography>Treatment for anal fissures aims to relieve symptoms, promote healing, and prevent recurrence. The following treatment options are available:</Typography>

                        <ul>
                            <li>
                                Lifestyle and Home Remedies
                                <ul>
                                    <li>
                                        <b>        Dietary Changes: </b>Increasing fiber intake and consuming an adequate amount of fluids can help soften the stool, making bowel movements easier.

                                    </li>
                                    <li>
                                        <b>       Sitz Baths: </b>Soaking the anal area in warm water can help relieve pain and promote healing.

                                    </li>

                                    <li>
                                        <b>      Proper Hygiene: </b>Keeping the anal area clean and dry is important to prevent infection and further irritation.
                                    </li>

                                </ul>
                            </li>

                            <li>
                                Medications for Anal Fissure

                                <ul>
                                    <li>
                                        <b>  Topical Analgesics: </b>Over-the-counter or prescription creams or ointments containing local anesthetics can help numb the area and provide temporary pain relief.
                                    </li>
                                    <li>
                                        <b>       Stool Softeners: </b>These medications can help soften the stool, making bowel movements less painful.

                                    </li>

                                </ul>

                            </li>

                            <li>
                                Surgical Procedures for Anal Fissure

                                Surgery is usually considered when conservative measures fail to provide relief. Surgical options for anal fissures may include:
                                <ul>
                                    <li>
                                        <b>    Lateral Internal Sphincterotomy: </b>This procedure involves cutting a small portion of the internal anal sphincter muscle to relieve spasm and promote healing.

                                    </li>

                                    <li>
                                        <b>   Fissurectomy: </b>In this procedure, the surgeon removes the entire fissure, allowing healthy tissue to grow in its place.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <Typography variant="h2"><b>Preventing Anal Fissure</b></Typography>

                        <Typography>To prevent the development or recurrence of anal fissures, the following measures can be helpful:</Typography>

                        <ul>
                            <li><b>Maintain Good Bowel Habits:</b> Eat a high-fiber diet, drink plenty of fluids, and establish regular bowel movements to prevent constipation and straining.
                            </li>

                            <li>
                                <b>   Avoid Excessive Wiping: </b>Gently pat or blot the anal area after bowel movements instead of vigorous wiping.

                            </li>

                            <li>
                                <b>   Practice Proper Hygiene: </b>Keep the anal area clean and dry, and avoid using harsh soaps or irritants.

                            </li>
                        </ul>



                        <Typography variant="h2"><b>SVV Clinic: Expert Care for Anal Fissure</b></Typography>


                        <Typography>SVV Clinic is a specialized medical facility that offers expert care for individuals suffering from anal fissures. The clinic provides comprehensive evaluation, diagnosis, and personalized treatment plans for patients. With a team of experienced healthcare professionals and advanced surgical techniques, SVV Clinic aims to alleviate pain, promote healing, and improve the quality of life for individuals with anal fissures.
                        </Typography>

                        <Typography variant="h2"><b>Conclusion</b></Typography>


                        <Typography>Anal fissures can be painful and disruptive, but with proper diagnosis and treatment, individuals can find relief and restore their comfort. Understanding the causes, recognizing the symptoms, and exploring treatment options are crucial for managing anal fissures effectively. SVV Clinic offers specialized care and support, ensuring that patients receive the expert treatment they need to overcome the challenges posed by anal fissures.
                        </Typography>
                        <Typography variant="h4">Frequently Asked Questions (FAQs)</Typography>

                        <Box>
                            <Box style={{ marginTop: 10 }} >
                                <Box className="FAQS" onClick={() => handleCloseData(Show, setShow, 'Q')}>
                                    <Typography variant="h2"><b>Q1. Can anal fissures heal on their own without treatment?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show ? <ArrowDropUpIcon onClick={handleshow} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q" >Ans: Some anal fissures may heal on their own with lifestyle changes and home remedies. However, persistent or recurrent fissures may require medical intervention.
                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show1, setShow1, 'Q1')} >
                                    <Typography variant="h2" style={{ fontSize: '14px !important' }}><b>Q2. How long does it take for an anal fissure to heal?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show1 ? <ArrowDropUpIcon onClick={handleshow1} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show1} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q1" >Ans: Most acute anal fissures heal within a few weeks with conservative treatments. Chronic or complex fissures may take longer to heal, and surgical intervention may be necessary.</Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show2, setShow2, 'Q2')} >
                                    <Typography variant="h2"><b>Q3. Can anal fissures recur after treatment?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show2 ? <ArrowDropUpIcon onClick={handleshow2} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show2} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q2" >Ans: Anal fissures can recur, especially if the underlying causes, such as constipation, are not adequately addressed. Proper prevention measures and lifestyle modifications can help reduce the risk of recurrence.
                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show3, setShow3, 'Q3')}>
                                    <Typography variant="h2"><b>Q4. Are there any complications associated with anal fissures?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show3 ? <ArrowDropUpIcon onClick={handleshow3} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show3} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q3" >Ans: While most anal fissures heal without complications, chronic or untreated fissures can lead to infections, abscesses, or the formation of skin tags in the anal area.
                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show4, setShow4, 'Q4')}>
                                    <Typography variant="h2"><b>Q5. Can SVV Clinic provide support for individuals with anal fissures?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show4 ? <ArrowDropUpIcon onClick={handleshow4} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show4} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q4" >Ans: Yes, SVV Clinic offers comprehensive care and support services for individuals suffering from anal fissures, including expert medical evaluation, personalized treatment plans, and guidance for prevention and management.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="Appointement-Box Appointment-Box-Mobile  ">
                        <Box className="Product-page-right">
                            <Box className="Product-page-right-frame">
                                <Box className="Product-page-right-wrapper">
                                    <img src="/svv clinic side image.png" />
                                    <Box className="Product-page-right-text">
                                        <h5>Dr. Vireesha </h5>
                                        <hr />
                                        <h6>
                                        Expert in Anorectal problems & Kshar sutra karma
                                        </h6>
                                        <h5>Contact Info </h5>
                                        <ul style={{ padding: "0px" }}>


                                            <li style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <PhoneIcon style={{ color: "#fff" }} /><a href="tel:+919518312766">+919518312766</a>
                                            </li>
                                            <li style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 10 }}>
                                                <EmailIcon style={{ color: "#fff" }} />   <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank">svvclinic@gmail.com</a>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="Product-page-right-book-appoi-container">
                            <h4>Book An Appointment</h4>
                            <Box>
                                <Box>
                                    <form>
                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Name" name="name" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Email" name="email" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Phone" type="number" name="phone" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <textarea rows={4} cols={40} aria-required={true} aria-invalid={false} name="msg" placeholder="Message (Optional)" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p style={{ textAlign: 'center' }}>
                                            <label>
                                                <span>
                                                    <Button variant="contained" onClick={Submit}>Submit</Button>
                                                </span>
                                            </label>
                                        </p>
                                    </form>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            <AlertDialog open={open} setOpen={setOpen} />
        </Box>
    )
}



// 3rd

export const Obsteric = () => {



    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
    const [Show, setShow] = useState(false);
    const [Show1, setShow1] = useState(false)
    const [Show2, setShow2] = useState(false);
    const [Show3, setShow3] = useState(false);
    const [Show4, setShow4] = useState(false)


    const show = () => {
        setShow(true);


        document.getElementById("Q").style.display = "block";


    }

    const handleshow = () => {
        setShow(false);
        document.getElementById("Q").style.display = "none";
    }

    const show1 = () => {
        setShow1(true);
        document.getElementById("Q1").style.display = "block";
    }

    const handleshow1 = () => {
        setShow1(false);
        document.getElementById("Q1").style.display = "none";
    }

    const show2 = () => {
        setShow2(true);
        document.getElementById("Q2").style.display = "block";
    }

    const handleshow2 = () => {
        setShow2(false);
        document.getElementById("Q2").style.display = "none";
    }

    const show3 = () => {
        setShow3(true);
        document.getElementById("Q3").style.display = "block";
    }

    const handleshow3 = () => {
        setShow3(false);
        document.getElementById("Q3").style.display = "none";
    }



    const show4 = () => {
        setShow4(true);
        document.getElementById("Q4").style.display = "block";

    }
    const handleshow4 = () => {
        setShow4(false);
        document.getElementById("Q4").style.display = "none";
    }


    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });

    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length != 10 || UserData.phone < 1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else {

            const data = new FormData()
            data.append("Name", UserData.name)
            data.append("Message", UserData.msg);
            data.append("Subject", UserData.subject);
            data.append("Mobile", UserData.phone);
            data.append("Email", UserData.email);
            data.append("Time", UserData.Time);
            data.append("Date", UserData.Date);
            const Response = await Book_Appointement(data);
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }
const handleCloseData = (ShowData, setShowData, id) => {
        {
            if (!ShowData) { setShowData(true); document.getElementById(id).style.display = 'block' }
            else {
                setShowData(false); document.getElementById(id).style.display = 'none';

            }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("MetaTitle").setAttribute("content", "What is Obstetric Fistula ? ");
        document.getElementById("MetaDescription").setAttribute("content", "Obstetric fistula is a severe childbirth injury that occurs during prolonged or obstructed labor. It involves the formation of an abnormal passage between the birth canal and the bladder or rectum, leading to continuous and involuntary leakage of urine or feces. Obstetric fistulas typically result from pressure exerted on the tissues due to prolonged labor without access to proper medical interventions.")
    }, [])

    return (
        <Box>
            <Box>
                <Box className="About-Bg fistula-bg" >
                    <h1>Obsteric Fistula Treatment</h1>
                    <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page" >Home</a> >   <a href="/Treatment/Obsteric" title="Go To Obsteric Fistula Page" >Obsteric Fistula Treatment</a></Typography>
                </Box>
                <Box className="Product-main"  >
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h1">Understanding Obstetric Fistula: Causes, Symptoms and Treatment</Typography>


                        <Typography variant="h2"><b>What is Obstetric Fistula?</b></Typography>

                        <Typography>
                            Obstetric fistula is a severe childbirth injury that occurs during prolonged or obstructed labor. It involves the formation of an abnormal passage between the birth canal and the bladder or rectum, leading to continuous and involuntary leakage of urine or feces. Obstetric fistulas typically result from pressure exerted on the tissues due to prolonged labor without access to proper medical interventions.

                        </Typography>

                        <Typography variant="h2"><b>Causes of Obstetric Fistula</b></Typography>

                        <Typography>The primary cause of obstetric fistula is inadequate or absent medical care during childbirth, especially in areas with limited resources and access to skilled birth attendants.
                        </Typography>

                        <Typography>Several factors contribute to its occurrence:
                        </Typography>
                        <ul>
                            <li>
                                <b>       Prolonged Labor: </b>When labor lasts for an extended period, the tissues of the birth canal may become damaged, leading to the formation of a fistula.

                            </li>

                            <li>
                                <b>        Lack of Emergency Obstetric Care: </b>Inadequate access to emergency obstetric care, including timely cesarean sections, increases the risk of developing obstetric fistula.

                            </li>

                            <li>
                                <b>        Young Maternal Age: </b>Adolescent girls who give birth are more susceptible to experiencing obstructed labor and developing fistulas.
                            </li>

                            <li>
                                <b>        Malnutrition:</b> Poor nutrition can weaken the body and contribute to obstructed labor and the development of obstetric fistula.

                            </li>

                            <li>
                                <b>        Lack of Skilled Birth Attendants: </b> Deliveries conducted by untrained individuals or traditional birth attendants without proper medical knowledge can lead to fistula formation.
                            </li>

                        </ul>

                        <Typography variant="h2"><b>Symptoms of Obstetric Fistula</b></Typography>

                        <Typography>The symptoms of obstetric fistula can have a significant physical and emotional impact on affected women. </Typography>
                        <Typography>Common symptoms include:
                        </Typography>
                        <ul>
                            <li>
                                Continuous leakage of urine or feces through the vagina.

                            </li>

                            <li>
                                Foul-smelling odor due to the leakage
                            </li>

                            <li>
                                Chronic infections in the urinary or reproductive tract.
                            </li>

                            <li>
                                Skin irritation and inflammation in the genital area.

                            </li>

                            <li>
                                Psychological distress, including social isolation and depression.
                            </li>
                        </ul>

                        <Typography variant="h2"><b>Physical and Emotional Impact</b></Typography>

                        <Typography>Obstetric fistula not only causes physical discomfort but also has profound emotional and social consequences for affected women. The constant leakage of urine or feces can lead to social stigma, marital strain, and exclusion from their communities. The emotional toll includes feelings of shame, low self-esteem and depression. Access to comprehensive care and support services is crucial for addressing these challenges.</Typography>


                        <Typography variant="h2"><b>Diagnosing Obstetric Fistula</b></Typography>

                        <Typography>Diagnosing obstetric fistula involves a combination of medical history review, physical examination, and specialized tests. </Typography>
                        <Typography>The diagnosis may include:</Typography>
                        <ul>
                            <li>
                                <b>   Medical History Review: </b>The healthcare provider will inquire about the patient's obstetric history, including details about previous pregnancies and deliveries.

                            </li>
                            <li>
                                <b>   Physical Examination: </b>A thorough pelvic examination is performed to evaluate the presence of fistula, the extent of the injury and any associated complications.
                            </li>

                            <li>
                                <b>  Specialized Tests: </b>Additional tests, such as dye tests, ultrasound or cystoscopy, may be conducted to visualize and confirm the presence of the fistula.


                            </li>

                        </ul>


                        <Typography variant="h2"><b>Treatment Options for Obstetric Fistula</b></Typography>

                        <Typography>The primary treatment for an obstetric fistula involves surgical repair of the abnormal connection between the birth canal and the bladder or rectum. The goal of treatment is to restore normal urinary and bowel function, alleviate symptoms and improve the quality of life for affected women. The treatment options may include:
                        </Typography>

                        <ul>
                            <li>
                                Surgical Repair for Obstetric Fistula
                                <ul>
                                    <li>
                                        Surgical repair is the mainstay treatment for obstetric fistula. Skilled healthcare professionals perform reconstructive surgery to close the fistula and restore the normal anatomy. The specific surgical technique used depends on the size, location and complexity of the fistula. Postoperative care, including wound management, is crucial for successful recovery.

                                    </li>


                                </ul>
                            </li>

                            <li>
                                Rehabilitation and Support Services

                                <Typography>Comprehensive care for obstetric fistula includes rehabilitation and support services to address the physical, emotional, and social needs of affected women. These services may include:</Typography>

                                <ul>
                                    <li>
                                        <b>    Physiotherapy:</b> To aid in bladder or bowel control and strengthen pelvic floor muscles.

                                    </li>
                                    <li>
                                        <b>    Counseling and Mental Health Support: </b>To address the emotional and psychological impact of obstetric fistula.

                                    </li>

                                    <li>
                                        <b>   Social Reintegration: </b> Support programs to help affected women reintegrate into their communities and rebuild their lives.

                                    </li>

                                </ul>

                            </li>


                        </ul>

                        <Typography variant="h2"><b>Preventing Obstetric Fistula</b></Typography>

                        <Typography>Preventing obstetric fistula requires a comprehensive approach that includes:
                        </Typography>

                        <ul>
                            <li> <b> Access to Skilled Obstetric Care: </b> Ensuring access to skilled healthcare professionals during pregnancy, childbirth and the postpartum period is crucial.


                            </li>

                            <li>
                                <b>       Timely Medical Interventions:</b> Prompt access to emergency obstetric care, including cesarean sections when necessary, can help prevent complications.


                            </li>

                            <li>
                                <b>      Education and Empowerment: </b> Providing education on reproductive health, family planning and nutrition can help reduce the incidence of obstetric fistula.

                            </li>
                        </ul>



                        <Typography variant="h2"><b>Empowering Women Through Treatment and Support:</b></Typography>


                        <Typography>SVV Clinic is a specialized medical facility dedicated to providing comprehensive care for women suffering from obstetric fistula. The clinic offers a multidisciplinary approach, including highly skilled medical professionals, state-of-the-art surgical techniques, rehabilitation services and psychosocial support. SVV Clinic aims to empower women by providing them with the necessary treatment and support to regain their health, dignity and quality of life.
                        </Typography>

                        <Typography variant="h2"><b>Conclusion </b></Typography>


                        <Typography>Obstetric fistula is a devastating childbirth injury that affects women in resource-constrained settings. Understanding its causes, recognizing the symptoms and providing access to specialized care and support are essential for the prevention and treatment of obstetric fistula. Through surgical interventions, rehabilitation and comprehensive support services, affected women can regain their physical and emotional well-being, fostering a path to a brighter future.

                        </Typography>
                        <Typography variant="h4">Frequently Asked Questions (FAQs)</Typography>

                        <Box>
                            <Box style={{ marginTop: 10 }} >
                                <Box className="FAQS" onClick={() => handleCloseData(Show, setShow, 'Q')} >
                                    <Typography variant="h2"><b>Q1. Can obstetric fistulas be prevented?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show ? <ArrowDropUpIcon onClick={handleshow} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q" >Ans:  Obstetric fistulas can be prevented by ensuring access to skilled obstetric care, timely medical interventions and comprehensive education on reproductive health.


                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show1, setShow1, 'Q1')}>
                                    <Typography variant="h2"><b>Q2. How successful is surgical repair for an obstetric fistula?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show1 ? <ArrowDropUpIcon onClick={handleshow1} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show1} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q1" >Ans: The success rate of surgical repair depends on various factors, such as the size and complexity of the fistula, the surgical technique used and the individual patient's health. Skilled surgeons and proper postoperative care greatly enhance the chances of successful outcomes.

                                </Typography>
                            </Box>

                        </Box>
                    </Box>

                    <Box className="Appointement-Box Appointment-Box-Mobile  ">
                        <Box className="Product-page-right">
                            <Box className="Product-page-right-frame">
                                <Box className="Product-page-right-wrapper">
                                    <img src="/svv clinic side image.png" />
                                    <Box className="Product-page-right-text">
                                        <h5>Dr. Vireesha </h5>
                                        <hr />
                                        <h6 style={{textDecoration:'Capitalize'}}>
                                            Expert in Anorectal problems & Kshar sutra karma
                                        </h6>
                                        <h5>Contact Info </h5>
                                        <ul style={{ padding: "0px" }}>


                                            <li style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <PhoneIcon style={{ color: "#fff" }} /><a href="tel:+919518312766">+919518312766</a>
                                            </li>
                                            <li style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 10 }}>
                                                <EmailIcon style={{ color: "#fff" }} />   <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank">svvclinic@gmail.com</a>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="Product-page-right-book-appoi-container">
                            <h4>Book An Appointment</h4>
                            <Box>
                                <Box>
                                    <form>
                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Name" name="name" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Email" name="email" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Phone" type="number" name="phone" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <textarea rows={4} cols={40} aria-required={true} aria-invalid={false} name="msg" placeholder="Message (Optional)" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p style={{ textAlign: 'center' }}>
                                            <label>
                                                <span>
                                                    <Button variant="contained" onClick={Submit}>Submit</Button>
                                                </span>
                                            </label>
                                        </p>
                                    </form>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            <AlertDialog open={open} setOpen={setOpen} />
        </Box>
    )
}

// 4th 


export const Constipation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("MetaTitle").setAttribute("content", "What is Constipation?");
        document.getElementById("MetaDescription").setAttribute("content", "Constipation refers to irregular bowel movements or difficulty in passing stools. It occurs when the stool moves too slowly through the digestive tract, resulting in hard and dry stools that are challenging to eliminate. It can lead to discomfort, pain, and a sense of incomplete bowel movements.")
    }, [])

    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
    const [Show, setShow] = useState(false);
    const [Show1, setShow1] = useState(false)
    const [Show2, setShow2] = useState(false);
    const [Show3, setShow3] = useState(false);
    const [Show4, setShow4] = useState(false)


    const show = () => {
        setShow(true);
        document.getElementById("Q").style.display = "block";
    }

    const handleshow = () => {
        setShow(false);
        document.getElementById("Q").style.display = "none";
    }

    const show1 = () => {
        setShow1(true);
        document.getElementById("Q1").style.display = "block";
    }

    const handleshow1 = () => {
        setShow1(false);
        document.getElementById("Q1").style.display = "none";
    }

    const show2 = () => {
        setShow2(true);
        document.getElementById("Q2").style.display = "block";
    }

    const handleshow2 = () => {
        setShow2(false);
        document.getElementById("Q2").style.display = "none";
    }

    const show3 = () => {
        setShow3(true);
        document.getElementById("Q3").style.display = "block";
    }

    const handleshow3 = () => {
        setShow3(false);
        document.getElementById("Q3").style.display = "none";
    }



    const show4 = () => {
        setShow4(true);
        document.getElementById("Q4").style.display = "block";

    }
    const handleshow4 = () => {
        setShow4(false);
        document.getElementById("Q4").style.display = "none";
    }


    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });

    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length != 10 || UserData.phone < 1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else {

            const data = new FormData()
            data.append("Name", UserData.name)
            data.append("Message", UserData.msg);
            data.append("Subject", UserData.subject);
            data.append("Mobile", UserData.phone);
            data.append("Email", UserData.email);
            data.append("Time", UserData.Time);
            data.append("Date", UserData.Date);
            const Response = await Book_Appointement(data);
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }
const handleCloseData = (ShowData, setShowData, id) => {
        {
            if (!ShowData) { setShowData(true); document.getElementById(id).style.display = 'block' }
            else {
                setShowData(false); document.getElementById(id).style.display = 'none';

            }
        }
    }

    return (
        <Box>
            <Box>
                <Box className="About-Bg Constipation-bg" >
                    <h1 >Constipation Treatment</h1>
                    <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page" >Home</a> >   <a href="/Treatment/Constipation" title="Go To Constipation Treatment Page" >Constipation Treatment</a></Typography>
                </Box>
                <Box className="Product-main"  >
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h1">Understanding Constipation: Causes, Symptoms and Treatment</Typography>

                        <Typography variant="h2"><b>What is Constipation?</b></Typography>

                        <Typography>
                            Constipation refers to irregular bowel movements or difficulty in passing stools. It occurs when the stool moves too slowly through the digestive tract, resulting in hard and dry stools that are challenging to eliminate. It can lead to discomfort, pain and a sense of incomplete bowel movements.


                        </Typography>

                        <Typography variant="h2"><b>Causes of Constipation</b></Typography>

                        <Typography>There are several factors that can contribute to constipation, including:

                        </Typography>

                        <ul>
                            <li>
                                <b>    Inadequate Fiber Intake:</b>  A diet low in fiber can lead to constipation as fiber adds bulk to the stool, facilitating its movement through the intestines.


                            </li>

                            <li>
                                <b>    Insufficient Fluid Intake: </b>Dehydration can make the stool harder and difficult to pass.

                            </li>

                            <li>
                                <b>    Lack of Physical Activity: </b>A sedentary lifestyle can contribute to sluggish bowel movements.

                            </li>

                            <li>
                                <b>    Medications:</b> Certain medications like painkillers, antidepressants and iron supplements can cause constipation as a side effect.


                            </li>
                            <li>
                                <b>   Medical Conditions: </b>Underlying medical conditions such as hypothyroidism, irritable bowel syndrome (IBS) and colon cancer can also result in constipation.

                            </li>


                        </ul>

                        <Typography variant="h2"><b>Symptoms of Constipation</b></Typography>

                        <Typography>The symptoms of constipation may vary from person to person but commonly include:</Typography>

                        <ul>
                            <li>
                                Infrequent bowel movements (less than three times per week)

                            </li>

                            <li>
                                Difficulty and straining during bowel movements


                            </li>

                            <li>
                                Hard and dry stools

                            </li>

                            <li>
                                Abdominal discomfort and bloating

                            </li>

                            <li>
                                Feeling of incomplete evacuation


                            </li>
                        </ul>

                        <Typography variant="h2"><b>Diagnosing Constipation</b></Typography>

                        <Typography>To diagnose constipation, healthcare professionals evaluate the patient's medical history, conduct a physical examination and may perform additional tests if necessary. These tests may include blood tests, imaging studies or a colonoscopy to rule out underlying conditions.
                        </Typography>

                        <Typography variant="h2"><b>Treatment Options for Constipation</b></Typography>

                        <Typography>Constipation can often be managed with lifestyle modifications and over-the-counter remedies. </Typography>
                        <Typography>Here are some treatment options:
                        </Typography>
                        <ul>
                            <li>
                                Lifestyle Changes to Relieve Constipation
                                <ul>
                                    <li>
                                        <b>   Increase Fiber Intake: </b>Consuming a diet rich in fruits, vegetables, whole grains and legumes can improve bowel regularity.

                                    </li>

                                    <li>
                                        <b>  Stay Hydrated: </b>Drinking an adequate amount of water helps soften the stool.

                                    </li>
                                    <li><b>  Regular Exercise: </b>Engaging in physical activity stimulates bowel movements and improves overall digestive health
                                    </li>

                                </ul>
                            </li>

                            <li>
                                Dietary Modifications for Constipation


                                <ul>
                                    <li>
                                        <b>     Fiber Supplements: </b>In some cases, fiber supplements like psyllium husk or methylcellulose may be recommended.

                                    </li>
                                    <li>
                                        <b>     Prunes and Prune Juice: </b>Prunes contain natural laxatives that can aid in relieving constipation.

                                    </li>

                                    <li>
                                        <b>     Avoiding trigger foods: </b>Certain foods like processed snacks, dairy products and red meat can contribute to constipation in some individuals.
                                    </li>

                                </ul>

                            </li>

                            <li>
                                Over-the-Counter Medications for Constipation

                                <ul>
                                    <li>
                                        <b>      Stimulant Laxatives: </b>These medications promote bowel movements by irritating the intestines and stimulating contractions.
                                    </li>

                                    <li>
                                        <b>     Stool softeners: </b>Stool softeners help soften the stool, making it easier to pass.

                                    </li>
                                </ul>
                            </li>

                            <li>
                                Prescription Medications for Constipation
                                <ul>
                                    <li>
                                        In severe cases or when other treatments fail, healthcare providers may prescribe medications such as osmotic laxatives, lubiprostone or linaclotide to alleviate constipation.

                                    </li>
                                </ul>

                            </li>

                            <li>
                                Natural Remedies for Constipation

                                <ul>
                                    <li>
                                        Some natural remedies that can provide relief from constipation include herbal teas, probiotics and abdominal massage techniques. However, it is important to consult a healthcare professional before trying any natural remedies.

                                    </li>
                                </ul>

                            </li>

                        </ul>
                        <Typography variant="h2"><b>When to Seek Medical Attention</b></Typography>


                        <Typography>While most cases of constipation can be managed at home, certain signs may indicate the need for medical attention. Seek medical help if you experience:
                        </Typography>

                        <ul>
                            <li>
                                Persistent constipation lasting for more than two weeks
                            </li>

                            <li>
                                Severe abdominal pain or rectal bleeding


                            </li>

                            <li>
                                Unexplained weight loss
                            </li>

                            <li>
                                Changes in bowel habits in individuals over 50 years old
                            </li>
                        </ul>
                        <Typography variant="h2"><b>Preventing Constipation</b></Typography>

                        <Typography>To prevent constipation, it is essential to maintain a healthy lifestyle. Some preventive measures include:
                        </Typography>

                        <ul>
                            <li>
                                Eating a balanced diet rich in fiber
                            </li>

                            <li>
                                Drinking an adequate amount of water

                            </li>

                            <li>
                                Regular exercise and physical activity
                            </li>

                            <li>
                                Avoiding long periods of sitting or inactivity
                            </li>
                        </ul>

                        <Typography variant="h2"><b>SVV Clinic: Providing Relief for Constipation</b></Typography>

                        <Typography>The SVV Clinic is a specialized medical facility that focuses on the diagnosis, treatment and management of constipation. They offer a multidisciplinary approach, including expert medical professionals, advanced diagnostic tools and personalized treatment plans. SVV Clinic aims to provide effective and compassionate care to individuals suffering from constipation, improving their quality of life.
                        </Typography>
                        <Typography variant="h2"><b>Conclusion</b>
                        </Typography>

                        <Typography>Constipation is a common condition that can cause significant discomfort and inconvenience. By understanding its causes, recognizing the symptoms and exploring various treatment options, individuals can effectively manage constipation and alleviate its associated problems. Remember to consult a healthcare professional for an accurate diagnosis and personalized treatment plan.

                        </Typography>
                        <Typography variant="h4">Frequently Asked Questions (FAQs)</Typography>

                        <Box>
                            <Box style={{ marginTop: 10 }} >
                                <Box className="FAQS" onClick={() => handleCloseData(Show, setShow, 'Q')}>
                                    <Typography variant="h2"><b>Q1.Is constipation a serious medical condition?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show ? <ArrowDropUpIcon onClick={handleshow} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q">Ans: No, constipation is usually not a serious medical condition but can significantly impact one's quality of life. Seeking medical advice is recommended for persistent or severe cases.
                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show1, setShow1, 'Q1')}>
                                    <Typography variant="h2"><b>Q2. Can constipation be a sign of an underlying health problem?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show1 ? <ArrowDropUpIcon onClick={handleshow1} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show1} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q1">Ans: Yes, constipation can be a symptom of an underlying health issue such as hypothyroidism, diabetes or colon cancer. It is important to consult a healthcare professional for a proper evaluation.
                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show2, setShow2, 'Q2')} >
                                    <Typography variant="h2"><b>Q3.   Are there any natural remedies to relieve constipation?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show2 ? <ArrowDropUpIcon onClick={handleshow2} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show2} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q2">Ans:  Yes, some natural remedies like increasing fiber intake, consuming prunes and staying hydrated can help relieve constipation. However, it is advisable to consult a healthcare professional before trying any natural remedies.

                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show3, setShow3, 'Q3')} >
                                    <Typography variant="h2"><b>Q4. How long does constipation usually last?
                                    </b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show3 ? <ArrowDropUpIcon onClick={handleshow3} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show3} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q3">Ans: The duration of constipation can vary depending on the individual and underlying causes. In most cases, constipation resolves within a few days with lifestyle modifications and over-the-counter remedies.

                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show4, setShow4, 'Q4')} >
                                    <Typography variant="h2"><b>Q5.  Can constipation be prevented?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show4 ? <ArrowDropUpIcon onClick={handleshow4} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show4} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q4">Ans: Yes, adopting a healthy lifestyle, including a balanced diet rich in fiber, regular exercise and staying hydrated, can help prevent constipation.


                                </Typography>
                            </Box>




                        </Box>
                    </Box>

                    <Box className="Appointement-Box Appointment-Box-Mobile  ">
                        <Box className="Product-page-right">
                            <Box className="Product-page-right-frame">
                                <Box className="Product-page-right-wrapper">
                                    <img src="/svv clinic side image.png" />
                                    <Box className="Product-page-right-text">
                                        <h5>Dr. Vireesha </h5>
                                        <hr />
                                        <h6 style={{textDecoration:'Capitalize'}}>
                                            Expert in Anorectal problems & Kshar sutra karma
                                        </h6>
                                        <h5>Contact Info </h5>
                                        <ul style={{ padding: "0px" }}>


                                            <li style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <PhoneIcon style={{ color: "#fff" }} /><a href="tel:+919518312766">+919518312766</a>
                                            </li>
                                            <li style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 10 }}>
                                                <EmailIcon style={{ color: "#fff" }} />   <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank">svvclinic@gmail.com</a>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="Product-page-right-book-appoi-container">
                            <h4>Book An Appointment</h4>
                            <Box>
                                <Box>
                                    <form>
                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Name" name="name" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Email" name="email" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Phone" type="number" name="phone" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <textarea rows={4} cols={40} aria-required={true} aria-invalid={false} name="msg" placeholder="Message (Optional)" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p style={{ textAlign: 'center' }}>
                                            <label>
                                                <span>
                                                    <Button variant="contained" onClick={Submit}>Submit</Button>
                                                </span>
                                            </label>
                                        </p>
                                    </form>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            <AlertDialog open={open} setOpen={setOpen} />
        </Box>
    )
}


// 5th 


export const Fistula = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("MetaTitle").setAttribute("content", "What is an Anal Fistula?");
        document.getElementById("MetaDescription").setAttribute("content", "An anal fistula is an abnormal tunnel-like passage that develops between the inner lining of the anal canal or rectum and the skin around the anus. It usually occurs as a result of an infection or an abscess that forms in the anal glands. The infection causes a tunnel to form, leading to the formation of an anal fistula.")
    }, [])

    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
    const [Show, setShow] = useState(false);
    const [Show1, setShow1] = useState(false);
    const [Show2, setShow2] = useState(false);
    const [Show3, setShow3] = useState(false);
    const [Show4, setShow4] = useState(false)


    const show = () => {
        setShow(true);
        document.getElementById("Q").style.display = "block";

    }

    const handleshow = () => {
        setShow(false);
        document.getElementById("Q").style.display = "none";
    }

    const show1 = () => {
        setShow1(true);
        document.getElementById("Q1").style.display = "block";
    }

    const handleshow1 = () => {
        setShow1(false);
        document.getElementById("Q1").style.display = "none";
    }

    const show2 = () => {
        setShow2(true);
        document.getElementById("Q2").style.display = "block";
    }

    const handleshow2 = () => {
        setShow2(false);
        document.getElementById("Q2").style.display = "none";
    }

    const show3 = () => {
        setShow3(true);
        document.getElementById("Q3").style.display = "block";
    }

    const handleshow3 = () => {
        setShow3(false);
        document.getElementById("Q3").style.display = "none";
    }



    const show4 = () => {
        setShow4(true);
        document.getElementById("Q4").style.display = "block";

    }
    const handleshow4 = () => {
        setShow4(false);
        document.getElementById("Q4").style.display = "none";
    }



    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });

    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length != 10 || UserData.phone < 1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else {

            const data = new FormData()
            data.append("Name", UserData.name)
            data.append("Message", UserData.msg);
            data.append("Subject", UserData.subject);
            data.append("Mobile", UserData.phone);
            data.append("Email", UserData.email);
            data.append("Time", UserData.Time);
            data.append("Date", UserData.Date);
            const Response = await Book_Appointement(data);
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }
const handleCloseData = (ShowData, setShowData, id) => {
        {
            if (!ShowData) { setShowData(true); document.getElementById(id).style.display = 'block' }
            else {
                setShowData(false); document.getElementById(id).style.display = 'none';

            }
        }
    }

    return (
        <Box>
            <Box>
                <Box className="About-Bg anal-bg" >
                    <h1 >Anal Fistula Treatment</h1>
                    <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page" >Home</a> >   <a href="/Treatment/Fistula" title="Go To Anal Fistula Page">Anal Fistula Treatment</a></Typography>
                </Box>
                <Box className="Product-main"  >
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h1">Understanding Anal Fistula: Causes, Symptoms, and Treatment</Typography>


                        <Typography variant="h2"><b>What is an Anal Fistula?</b></Typography>

                        <Typography>
                            An anal fistula is an abnormal tunnel-like passage that develops between the inner lining of the anal canal or rectum and the skin around the anus. It usually occurs as a result of an infection or an abscess that forms in the anal glands. The infection causes a tunnel to form, leading to the formation of an anal fistula.


                        </Typography>

                        <Typography variant="h2"><b>Causes of Anal Fistula</b></Typography>

                        <Typography>The primary cause of anal fistula is an infection or an abscess that develops in the anal glands. The infection can result from various factors, including:
                        </Typography>

                        <ul>
                            <li>
                                <b>   Anal Abscess: </b>An untreated or inadequately treated anal abscess can progress into an anal fistula.



                            </li>

                            <li>
                                <b>  Crohn's Disease:</b> This chronic inflammatory bowel disease can increase the risk of developing anal fistulas.

                            </li>

                            <li>
                                <b>   Sexually Transmitted Infections (STIs): </b>Certain STIs, such as gonorrhea or syphilis, can lead to the formation of anal fistulas.

                            </li>

                            <li>
                                <b>   Anal Trauma: </b>Injury to the anal area, such as through rough wiping or anal sex can lead to the formation of anal fissures.

                            </li>
                            <li>
                                <b>   Tuberculosis: </b>In rare cases, tuberculosis infection can cause anal fistulas.
                            </li>


                        </ul>

                        <Typography variant="h2"><b>Symptoms of Anal Fistula</b></Typography>

                        <Typography>The symptoms of anal fistula may vary depending on the location and complexity of the fistula. Common signs and symptoms include:</Typography>

                        <ul>
                            <li>
                                Persistent anal pain
                            </li>

                            <li>
                                Swelling around the anus

                            </li>

                            <li>
                                Discharge of pus or blood from the opening near the anus

                            </li>

                            <li>
                                Itching or irritation around the anus

                            </li>

                            <li>
                                Recurrent anal abscesses or infections
                            </li>
                        </ul>




                        <Typography variant="h2"><b>Diagnosing Anal Fistula</b></Typography>

                        <Typography>To diagnose an anal fistula, a healthcare professional will perform a physical examination and review the patient's medical history. Additional diagnostic procedures may include:
                        </Typography>
                        <ul>
                            <li>
                                <b>   Visual Examination: </b>The doctor will visually inspect the affected area to look for signs of a fistula or abscess.


                            </li>
                            <li>
                                <b>   Proctoscopy or Anoscopy: </b>These procedures involve using a special instrument to examine the anus and rectum more closely.



                            </li>

                            <li>
                                <b>   Imaging Tests: </b>In some cases, imaging tests like ultrasound, MRI or CT scan may be recommended to determine the exact location and extent of the fistula.


                            </li>

                        </ul>


                        <Typography variant="h2"><b>Treatment Options for Anal Fistula</b></Typography>

                        <Typography>The treatment for anal fistula aims to relieve symptoms, prevent recurrence and promote healing. The choice of treatment depends on the complexity and severity of the fistula. The following options are available:
                        </Typography>

                        <ul>
                            <li>
                                Lifestyle and Home Remedies

                                <ul>
                                    <li>
                                        <b>    Sitz Baths:</b> Taking warm baths can help soothe the anal area and promote healing.
                                    </li>

                                    <li>
                                        <b>    Maintaining Good Anal Hygiene:</b> Keeping the anal area clean and dry is important to prevent infection and promote healing.
                                    </li>

                                    <li>
                                        <b>    High-Fiber Diet: </b>Consuming a diet rich in fiber can help to soften the stools and prevent constipation, which may alleviate symptoms.


                                    </li>

                                </ul>
                            </li>

                            <li>
                                Medications for Anal Fistula
                                <Typography>Medications are typically used to manage symptoms and prevent infection. They may include:
                                </Typography>



                                <ul>
                                    <li>
                                        <b>     Antibiotics:</b> To treat or prevent infection in the anal region.
                                    </li>
                                    <li>
                                        <b>   Pain Relievers: </b>Over-the-counter pain medications can help alleviate pain and discomfort.


                                    </li>



                                </ul>

                            </li>

                            <li>
                                Treatment and Ayurvedic Procedures for Anal Fistula
                                Ayurvedic procedures are often necessary to treat anal fistulas effectively.

                            </li>
                            <li>
                                The Ayurvedic procedures (Kshar Sutra procedure) options include:


                                <ul>
                                    <li>
                                        <b>   Fistulotomy:</b> This procedure involves cutting and opening the fistula tract to allow it to heal from inside out.



                                    </li>

                                    <li>
                                        <b>  Fistulotomy with Seton Placement:</b> A seton (a piece of surgical thread) is placed in the fistula tract to help drain and heal the area gradually.

                                    </li>

                                    <li>
                                        <b>   Fibrin Glue Injection: </b>In some cases, a special glue is injected into the fistula tract to seal it off and promote healing.
                                    </li>
                                    <li>
                                        <b>   Advancement Flap Procedure: </b>This procedure involves using nearby healthy tissue to cover the opening of the fistula and promote healing.
                                    </li>

                                </ul>
                            </li>
                        </ul>

                        <Typography variant="h2"><b>Complications of Anal Fistula</b></Typography>

                        <Typography>If left untreated or inadequately treated, anal fistulas can lead to various complications, including:
                        </Typography>

                        <ul>
                            <li><b>  Recurrent Abscesses: </b>The fistula can cause recurrent abscesses in the anal region.

                            </li>

                            <li>
                                <b>  Chronic Discharge:</b> The opening of the fistula may continuously release discharge, leading to irritation and discomfort.
                            </li>

                            <li>
                                <b>   Infection:</b> Untreated anal fistulas can lead to severe infections that may require emergency medical attention.

                            </li>
                        </ul>
                        <Typography variant="h2"><b>Preventing Anal Fistula</b></Typography>
                        <Typography>While not all cases of anal fistula can be prevented, some measures can help reduce the risk of developing the condition. These include:
                        </Typography>
                        <ul>
                            <li>
                                <b>    Treating Anal Abscesses Promptly:</b> Seeking medical attention and appropriate treatment for anal abscesses can help prevent the progression to anal fistulas.

                            </li>
                            <li>
                                <b>    Maintaining Good Anal Hygiene: </b>Keeping the anal area clean and dry, which can help prevent infection.

                            </li>

                            <li>
                                <b>    Managing Underlying Conditions: </b> For individuals with conditions like Crohn's disease or tuberculosis, effectively managing the underlying condition can reduce the risk of developing anal fistulas.

                            </li>
                        </ul>

                        <Typography variant="h2"><b>Conclusion</b></Typography>

                        <Typography>Anal fistula is a challenging condition that can cause pain, discomfort and recurrent infections. Understanding the causes, recognizing the symptoms and seeking appropriate treatment are crucial for managing this condition effectively. With the help of lifestyle modifications, medications, and surgical procedures individuals can find relief from anal fistula and improve their quality of life.

                        </Typography>
                        <Typography variant="h4">Frequently Asked Questions (FAQs)</Typography>

                        <Box>
                            <Box style={{ marginTop: 10 }} >
                                <Box className="FAQS" onClick={() => handleCloseData(Show, setShow, 'Q')} >
                                    <Typography variant="h2"><b>Q1. Can an anal fistula heal on its own without treatment?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show ? <ArrowDropUpIcon onClick={handleshow} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q">Ans:  No, Anal fistulas rarely heal on their own. Treatment is usually necessary to alleviate symptoms, prevent complications and promote healing.


                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show1, setShow1, 'Q1')}>
                                    <Typography variant="h2"><b>Q2.   Are all anal fistulas the result of an abscess?
                                    </b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show1 ? <ArrowDropUpIcon onClick={handleshow1} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show1} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q1">Ans:  Yes, The majority of anal fistulas develop as a result of an infection or abscess, but there can be other causes as well, such as Crohn's disease or STIs.


                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show2, setShow2, 'Q2')}>
                                    <Typography variant="h2"><b>Q3. Is surgery always required to treat an anal fistula?
                                    </b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show2 ? <ArrowDropUpIcon onClick={handleshow2} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show2} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q2">Ans:  No, Surgery is often necessary for effective treatment of anal fistulas. However, the choice of surgical procedure depends on the complexity and severity of the fistula. Initial recognition and treatments will avoid surgery.

                                </Typography>
                            </Box>


                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show3, setShow3, 'Q3')}>
                                    <Typography variant="h2"><b>Q4. Can anal fistulas recur after treatment?</b> </Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show3 ? <ArrowDropUpIcon onClick={handleshow3} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show3} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q3">Ans: No, While treatment aims to prevent recurrence, anal fistulas can sometimes recur even after surgery. Regular follow-up with a healthcare professional is essential.
                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show4, setShow4, 'Q4')}>
                                    <Typography variant="h2"><b>Q5. How long does it take to recover from anal fistula surgery?
                                    </b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show4 ? <ArrowDropUpIcon onClick={handleshow4} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show4} style={{ fontSize: 40 }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q4">Ans:  The recovery time can vary depending on the type of surgical procedure performed. It typically takes a few weeks to several months for complete healing
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="Appointement-Box Appointment-Box-Mobile  ">
                        <Box className="Product-page-right">
                            <Box className="Product-page-right-frame">
                                <Box className="Product-page-right-wrapper">
                                    <img src="/svv clinic side image.png" />
                                    <Box className="Product-page-right-text">
                                        <h5>Dr. Vireesha </h5>
                                        <hr />
                                        <h6 style={{textDecoration:'Capitalize'}}>
                                            Expert in Anorectal problems & Kshar sutra karma
                                        </h6>
                                        <h5>Contact Info </h5>
                                        <ul style={{ padding: "0px" }}>


                                            <li style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <PhoneIcon style={{ color: "#fff" }} /><a href="tel:+919518312766">+919518312766</a>
                                            </li>
                                            <li style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 10 }}>
                                                <EmailIcon style={{ color: "#fff" }} />   <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank">svvclinic@gmail.com</a>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="Product-page-right-book-appoi-container">
                            <h4>Book An Appointment</h4>
                            <Box>
                                <Box>
                                    <form>
                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Name" name="name" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Email" name="email" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Phone" type="number" name="phone" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <textarea rows={4} cols={40} aria-required={true} aria-invalid={false} name="msg" placeholder="Message (Optional)" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p style={{ textAlign: 'center' }}>
                                            <label>
                                                <span>
                                                    <Button variant="contained" onClick={Submit}>Submit</Button>
                                                </span>
                                            </label>
                                        </p>
                                    </form>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            <AlertDialog open={open} setOpen={setOpen} />
        </Box>
    )
}

// 6th
export const KashraSutra = () => {



    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
    const [Show, setShow] = useState(false);
    const [Show1, setShow1] = useState(false)
    const [Show2, setShow2] = useState(false);
    const [Show3, setShow3] = useState(false);
    const [Show4, setShow4] = useState(false)

    const show = () => {
        setShow(true);
        document.getElementById("Q1").style.display = "block";


    }

    const handleshow = () => {
        setShow(false);
        document.getElementById("Q1").style.display = "none";
    }

    const show1 = () => {
        setShow1(true);
        document.getElementById("Q2").style.display = "block";
    }

    const handleshow1 = () => {
        setShow1(false);
        document.getElementById("Q2").style.display = "none";
    }

    const show2 = () => {
        setShow2(true);
        document.getElementById("Q3").style.display = "block";
    }

    const handleshow2 = () => {
        setShow2(false);
        document.getElementById("Q3").style.display = "none";
    }

    const show3 = () => {
        setShow3(true);
        document.getElementById("Q4").style.display = "block";
    }

    const handleshow3 = () => {
        setShow3(false);
        document.getElementById("Q4").style.display = "none";
    }



    const show4 = () => {
        setShow4(true);
        document.getElementById("Q5").style.display = "block";

    }
    const handleshow4 = () => {
        setShow4(false);
        document.getElementById("Q5").style.display = "none";
    }


    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });

    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length != 10 || UserData.phone < 1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else {

            const data = new FormData()
            data.append("Name", UserData.name)
            data.append("Message", UserData.msg);
            data.append("Subject", UserData.subject);
            data.append("Mobile", UserData.phone);
            data.append("Email", UserData.email);
            data.append("Time", UserData.Time);
            data.append("Date", UserData.Date);
            const Response = await Book_Appointement(data);
            if (Response.status == 200) {
                toast.success("Your Appointement Successfully Booked");
                setOpen(true);
            }
            else {
                toast.error("Please Check Your Internet Connection");
            }
        }
    }
const handleCloseData = (ShowData, setShowData, id) => {
        {
            if (!ShowData) { setShowData(true); document.getElementById(id).style.display = 'block' }
            else {
                setShowData(false); document.getElementById(id).style.display = 'none';

            }
        }
    }
    useEffect(() => {

        window.scrollTo(0, 0);
        document.getElementById("MetaTitle").setAttribute("content", "What is an Anal Fissure ?");
        document.getElementById("MetaDescription").setAttribute("content", "An anal fissure is a small tear or split in the mucous membrane lining of the anus or lower rectum. It is often caused by passage of hard or large bowel movements, leading to pain and discomfort during bowel movements. Anal fissures are more common in adults and can occur both acutely and chronically.");
    }, [])
    return (
        <Box>
            <Box>
                <Box className="About-Bg kshra-bg" >
                    <h1 >Kshar Sutra Karma Treatment</h1>
                    <Typography variant="h5" className="Anchor"><a href="/" title="Go To Home Page" >Home</a> >   <a href="/Treatment/Kshar-Sutra" title="Go To Kshar Sutra Page" >Kshar Sutra (Procedure) Treatment</a></Typography>
                </Box>
                <Box className="Product-main"  >
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h1">  Kshar Sutra Karma : The Natural Ayurvedic Solution for Piles, Fistulas, and Fissures</Typography>


                        <Typography variant="h2"><b>What is Kshar Sutra Karma ?</b></Typography>
                        <Typography>Welcome to our website, dedicated to the profound healing potential of Kshar Sutra Karma, an ancient Ayurvedic procedure used to treat various anorectal conditions. Rooted in the holistic principles of Ayurveda, Kshar Sutra Karma offers a gentle and effective approach to healing piles, fistulas, fissures and other related ailments. In this article, we explore the history, uses, advantages and importance of Kshar Sutra Karma, highlighting its remarkable benefits.
                        </Typography>
                        <Typography variant="h2"><b>History :</b></Typography>
                        <Typography>
                            Kshar Sutra Karma boasts a rich history that dates back over 2,000 years, originating from the classical Ayurvedic texts, such as Sushruta Samhita. This ancient technique employs a specially medicated thread, known as the Kshar Sutra, for the treatment of anorectal disorders. The longevity of its practice and the positive results obtained over centuries have established Kshar Sutra Karma as a trusted and time-honored therapeutic procedure in Ayurveda.


                        </Typography>

                        <Typography variant="h2"><b>Uses :</b></Typography>

                        <Typography>Kshar Sutra Karma is primarily utilized for the management of anorectal conditions such as piles, fistulas and fissures. These conditions often cause significant discomfort, pain and impairment in daily life. However, Kshar Sutra Karma offers a safe and natural alternative to conventional surgical interventions. This procedure focuses on addressing the underlying causes of the condition, promotes healing and prevents recurrence. Additionally, Kshar Sutra Karma has shown promising results in managing other related disorders, including pilonidal sinus and hemorrhoids.
                        </Typography>

                        <Typography variant="h2"><b>Advantages :</b></Typography>
                        <Typography>Non-Surgical Approach: One of the significant advantages of Kshar Sutra Karma is its non-surgical nature. Unlike traditional surgeries, it eliminates the need for extensive incisions and reduces the risk of complications. This makes it an appealing option for individuals seeking a minimally invasive and safer treatment approach.
                            <br />
                            <b> Minimal Discomfort: </b> Kshar Sutra Karma is performed under local anesthesia, ensuring minimal discomfort for the patient. The procedure is relatively quick and it is typically performed on an outpatient basis. This allows individuals to resume their regular activities with minimal disruption.
                            <br />
                            <b>High Success Rate:</b> Kshar Sutra Karma has demonstrated a high success rate in the management of anorectal disorders. It not only provides relief from symptoms but also supports complete healing and prevents recurrence. This makes it a preferred choice for individuals seeking long-term solutions to their health concerns.
                        </Typography>

                        <Typography variant="h2"><b>Importance :</b></Typography>

                        <Typography>Kshar Sutra Karma holds immense importance within the realm of Ayurvedic medicine due to its holistic approach to healing anorectal conditions. It acknowledges the interconnectedness of the mind, body, spirit and strives to restore balance and harmony within the individual. By considering the unique needs of each patient and tailoring treatments accordingly, Kshar Sutra Karma ensures comprehensive and sustainable healing experiences. In conclusion, Kshar Sutra Karma is an ancient Ayurvedic procedure with a rich history and an array of benefits for the treatment of piles, fistula, fissures and related anorectal conditions.
                            Its non-surgical approach, minimal discomfort, high success rate and holistic nature make it an attractive choice for those seeking a natural and effective solution. We invite you to explore further and discover the transformative power of Kshar Sutra Karma for your well-being.

                        </Typography>


                        <Typography variant="h2"><b>Kshar Sutra: An Effective Treatment</b></Typography>

                        <Typography>Dr. Vireesha Bogireddy at SVV Clinic specializes in the Kshar Sutra procedure, an effective treatment for piles. Kshar Sutra is a non-surgical, Ayurvedic treatment that involves the use of a medicated thread. The procedure helps in the gradual detachment and removal of piles, promoting healing and providing long-term relief.</Typography>

                        <Typography variant="h2"><b>Preparing for Kshar Sutra Procedure</b></Typography>
                        <Typography>Before undergoing the Kshar Sutra procedure, it is important to follow certain preparations</Typography>

                        <ul>
                            <li>
                                <b> Consultation with Dr. Vireesha Bogireddy :  </b>Schedule a consultation with Dr. Vireesha Bogireddy to discuss your condition and determine if the Kshar Sutra procedure is suitable for you.

                            </li>
                            <li>
                                <b> Medical history evaluation: </b>Provide a detailed medical history, including any existing health conditions, medications or allergies, to ensure a safe and successful procedure.


                            </li>

                            <li>
                                <b>  Fasting and bowel preparation: </b> Dr. Vireesha Bogireddy will provide specific instructions regarding fasting and bowel preparation before the procedure to ensure optimal results.

                            </li>

                        </ul>


                        <Typography variant="h2"><b>The Process of Kshar Sutra</b></Typography>

                        <Typography>The Kshar Sutra procedure involves the following steps:</Typography>

                        <ul>
                            <li>
                                <b>Local anesthesia: </b>The area around the anus is numbed using local anesthesia to minimize discomfort during the procedure.
                            </li>

                            <li>
                                <b> Placement of the thread:</b> A medicated thread, known as the Kshar Sutra, is carefully inserted into the pile mass. The thread helps in cutting off the blood supply to the piles, leading to their gradual detachment.


                            </li>

                            <li>
                                <b> Regular Dressing: </b>The regular dressing and replacing thread at regular intervals to ensure the effective removal of piles.

                            </li>
                            <li>
                                <b>Healing and recovery: </b> As the piles gradually detach and heal, patients experience relief from symptoms such as pain, bleeding and swelling.


                            </li>
                        </ul>

                        <Typography variant="h2"><b>Recovery and Aftercare</b></Typography>

                        <Typography>After the Kshar Sutra procedure, it is important to follow proper aftercare instructions provided by Dr. Vireesha Bogireddy.
                        </Typography>

                        <Typography>This may include:</Typography>                        <ul>
                            <li>
                                <b>Pain management:</b> Over-the-counter pain relievers or prescribed medications can help manage any discomfort experienced after the procedure.


                            </li>

                            <li>
                                <b> Maintaining hygiene: </b> Keeping the anal area clean and dry is essential to prevent infections and promote healing.

                            </li>

                            <li>
                                <b>Dietary recommendations: </b> Dr. Vireesha Bogireddy may provide dietary recommendations to support healing and prevent constipation during the recovery period.


                            </li>
                        </ul>



                        <Typography variant="h2"><b> Lifestyle Changes to Prevent Recurrence
                        </b></Typography>


                        <Typography>To prevent the recurrence of piles, incorporating the following lifestyle changes is beneficial:
                        </Typography>
                        <ul>
                            <li>
                                <b>Maintain a healthy weight: </b> Obesity increases the risk of piles, so maintaining a healthy weight through a proper diet and regular exercise is important.


                            </li>
                            <li>
                                <b>Exercise regularly: </b> Engaging in physical activity, such as walking or swimming, helps promote healthy bowel movements and reduces the likelihood of developing piles.

                            </li>
                            <li>
                                <b>High-fiber diet:</b> Consuming foods rich in fiber, such as fruits, vegetables, whole grains and legumes, can prevent constipation and reduce the risk of piles.


                            </li>
                            <li>
                                <b>Stay hydrated: </b> Drinking an adequate amount of water throughout the day helps keep the stool soft and facilitates regular bowel movements.

                            </li>
                        </ul>

                        <Typography variant="h2"><b>Conclusion</b></Typography>


                        <Typography>In conclusion, piles or hemorrhoids are a common condition that can cause discomfort and pain. However, with proper understanding, preventive measures and effective treatments like the Kshar Sutra procedure offered by Dr. Vireesha Bogireddy at SVV Clinic, individuals can find relief and improve their quality of life. Remember to consult with a medical professional for personalized advice and treatment options.


                        </Typography>
                        <Typography variant="h4"><b>Frequently Asked Questions (FAQs)</b></Typography>

                        <Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show, setShow, 'Q1')} >
                                    <Typography variant="h2"><b>Q1. What are the common risk factors for developing piles?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show ? <ArrowDropUpIcon onClick={handleshow} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show} style={{ fontSize: 40 }} />}
                                    </Box>
                                </Box>
                                <Typography id="Q1">Ans:  Obesity, chronic constipation, pregnancy, aging and a sedentary lifestyle are common risk factors for developing piles.
                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show1, setShow1, 'Q2')} >
                                    <Typography variant="h2"><b>Q2. Can piles be treated without surgery?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show1 ? <ArrowDropUpIcon onClick={handleshow1} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show1} style={{ fontSize: 40 }} />}
                                    </Box>
                                </Box>
                                <Typography id="Q2">Ans: Yes, piles can be effectively treated without surgery through procedures such as the Kshar Sutra technique offered by Dr. Vireesha Bogireddy at SVV Clinic.
                                </Typography>
                            </Box>


                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show2, setShow2, 'Q3')} >
                                    <Typography variant="h2"><b>Q3. How long does the Kshar Sutra procedure take?</b></Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show2 ? <ArrowDropUpIcon onClick={handleshow2} style={{ fontSize: 40 }} /> : <ArrowDropDownIcon onClick={show2} style={{ fontSize: 40 }} />}
                                    </Box>
                                </Box>
                                <Typography id="Q3">Ans: The duration of the Kshar Sutra procedure may vary depending on the severity of the piles. Dr. Vireesha Bogireddy will provide you with an estimate based on your specific case.

                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show3, setShow3, 'Q4')}>
                                    <Typography variant="h2"><b>Q4. Is the Kshar Sutra procedure painful?</b>
                                    </Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show3 ? <ArrowDropUpIcon onClick={handleshow3} style={{ fontSize: 40, cursor: "pointer" }} /> : <ArrowDropDownIcon onClick={show3} style={{ fontSize: 40, cursor: "pointer" }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q4">Ans:No, The Kshar Sutra procedure is performed under local anesthesia, which helps minimize discomfort. Patients may experience mild discomfort during the recovery period, but pain can be managed with medications.

                                </Typography>
                            </Box>

                            <Box style={{ marginTop: 10 }}>
                                <Box className="FAQS" onClick={() => handleCloseData(Show4, setShow4, 'Q5')}>
                                    <Typography variant="h2"><b>Q5. Can lifestyle changes prevent the recurrence of piles?</b>
                                    </Typography>
                                    <Box style={{ marginLeft: "auto" }}>
                                        {Show4 ? <ArrowDropUpIcon onClick={handleshow4} style={{ fontSize: 40, cursor: "pointer" }} /> : <ArrowDropDownIcon onClick={show4} style={{ fontSize: 40, cursor: "pointer" }} />}
                                    </Box >
                                </Box>
                                <Typography id="Q5" style={{ display: "none" }}>Ans:Yes, adopting a healthy lifestyle that includes regular exercise, a high-fiber diet and maintaining proper hygiene can significantly reduce the chances of piles recurring.


                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="Appointement-Box Appointment-Box-Mobile  ">
                        <Box className="Product-page-right">
                            <Box className="Product-page-right-frame">
                                <Box className="Product-page-right-wrapper">
                                    <img src="/svv clinic side image.png" />
                                    <Box className="Product-page-right-text">
                                        <h5>Dr. Vireesha </h5>
                                        <hr />
                                        <h6 style={{textDecoration:'Capitalize'}}>
                                            Expert in Anorectal problems & Kshar sutra karma
                                        </h6>
                                        <h5>Contact Info </h5>
                                        <ul style={{ padding: "0px" }}>


                                            <li style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                                <PhoneIcon style={{ color: "#fff" }} /><a href="tel:+919518312766">+919518312766</a>
                                            </li>
                                            <li style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 10 }}>
                                                <EmailIcon style={{ color: "#fff" }} />   <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank">svvclinic@gmail.com</a>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="Product-page-right-book-appoi-container">
                            <h4>Book An Appointment</h4>
                            <Box>
                                <Box>
                                    <form>
                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Name" name="name" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Email" name="email" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <input placeholder="Phone" type="number" name="phone" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p>
                                            <label>
                                                <span>
                                                    <textarea rows={4} cols={40} aria-required={true} aria-invalid={false} name="msg" placeholder="Message (Optional)" onChange={Book} />
                                                </span>
                                            </label>
                                        </p>

                                        <p style={{ textAlign: 'center' }}>
                                            <label>
                                                <span>
                                                    <Button variant="contained" onClick={Submit}>Submit</Button>
                                                </span>
                                            </label>
                                        </p>
                                    </form>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
            <AlertDialog open={open} setOpen={setOpen} />
        </Box>
    )
}
