import { Box, Grid } from "@mui/material"
import ReactPlayer from "react-player/youtube"
import { GetVideo } from "../../Services/Api";
import { useEffect, useState } from "react";
export const Doctor = () => {
    const [videoUrl, setUrl] = useState([]);
    useEffect(() => {
        const GetVideoData = async () => {
            const response = await GetVideo();
       
            setUrl(response.data);
        }
        GetVideoData()
    },[])
    return (
        <Box style={{ padding: 30 , height:'100vh' }}>

            <Grid container columnSpacing={4} rowSpacing={4}>
                {
                    videoUrl.length > 0 ?

                        videoUrl.map(URL => (
                            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} >
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={URL.Link}
                                        width='100%'
                                        height='200px'
                                    />
                                </div>
                            </Grid>

                        ))
                        :
                        <Box style={{textAlign:'center' , width:'100%' , marginTop:30}}>
                            <h2 style={{textAlign:'center' , margin:'auto'}}>
                                You Will Watch video' s soon
                            </h2>
                        </Box>
                }


            </Grid>
        </Box>
    )
}