
import React, { useState, useEffect } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Box, Button } from "@mui/material"
import { PostBlogData } from '../../Services/Api';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from '@mui/icons-material/Add';
import ReactQuill, { Quill } from 'react-quill';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import QuillToggleFullscreenButton from 'quill-toggle-fullscreen-button';
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/toggleFullscreen', QuillToggleFullscreenButton);


const Data = {
    contents: "",
    tittle: "",
    description: "",
    image: "",
    route:"",
    meta_title:"",
    meta_description:""
}

var toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': [] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [],

    ['code-block', 'link', 'image', 'video'],           // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction


    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

    [{ 'align': [] }],


    ['clean']                                         // remove formatting button
];
var module = {
    toolbar: toolbarOptions
}






const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6] }],[ { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    
    toggleFullscreen: true,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };

 const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ];
export const Blog_post = () => {
    
    const [content, setContent] = useState(Data);
    const [file, setFile] = useState("");

    const SaveBlogData = (event) => {
        if(event.target.name=="image"){
            setContent({ ...content, [event.target.name]: event.target.files[0]});
             setFile( event.target.files[0].name);
        }else{
            setContent({ ...content, [event.target.name]: event.target.value });
        }
        
        
    }
    const SaveEditorData = (newContent) => {
        setContent({ ...content, contents: newContent });
        
    }

    const Submit = async () => {
        if (!content.tittle) {
            toast.error("Please Enter tittle")
        }
        else if (!content.image) {
            toast.error("Please Enter Thumbnail ")
        }
        else if (!content.route) {
            toast.error("Please Enter Route ")
        }
        else if (!content.description) {
            toast.error("Please Enter description ")
        }
        else if (!content.contents) {
            toast.error("Please Enter Somtheing aboout your blog ")
        }
        else {
            const fD = new FormData();
            fD.append("tittle",content.tittle);
            fD.append("description",content.description);
            fD.append("contents",content.contents);
            fD.append("image",content.image);
            fD.append("route",content.route);
            fD.append("meta_title",content.meta_title);
            fD.append("meta_description",content.meta_description);
            try{
            const response = await PostBlogData(fD);
            if (response.status == 200) {
                    toast.success("Your Blog  Successfull Post");
    
                }
            }catch(error){
                toast.error("Duplicate Route Enter Another Route")
            }
            
        }
    }
    return (<>
        <Box className="editPost" style={{width:"90%"}}>
            <input type="Textarea" placeholder="Title" name="tittle" value={content.tittle} onChange={SaveBlogData} />
            <input type='text' placeholder='Route'  name="route" value={content.route} style={{ color: "#757575", fontWeight: 600, fontSize: 16 }} onChange={SaveBlogData}  />
            <input type="Textarea" placeholder="Meta_title" name="meta_title" value={content.meta_title} onChange={SaveBlogData} />
            <textarea rows={5} cols={20} placeholder="Meta_description" name="meta_description" value={content.meta_description} onChange={SaveBlogData} />
            <Box className="Thumbnail">
                <Box style={{ width: "100%" }}>
                    <input type='text' placeholder='Select Thumbnail' readOnly value={file ? file : ""} style={{ color: "#757575", fontWeight: 600, fontSize: 16 }} />
                </Box>
                <Box className="Thumbnail-Lable">
                    <label htmlFor='thumbnail'>

                        <AddIcon fontSize="medium" color="action" style={{ border: "1px solid black", background: "#18AD96", borderRadius: "50%", }} />
                    </label>
                </Box>

            </Box>

            <input type="file" accept="image/*" placeholder="Thumbnail Url " id="thumbnail" name="image" onChange={(e) => SaveBlogData(e)} style={{ display: "none" }} />
            <textarea rows={7} cols={50} placeholder="Describe some Words About Your Blog at least 30 words" name="description" value={content.description} onChange={SaveBlogData} />

           
<Box style={{height:"40vh" , width:"90%"}}>
            <ReactQuill
            style={{height:"100%" , }}
        theme="snow"
        onChange={setValue => SaveEditorData(setValue)}
        modules={modules}
        formats={formats}
      />
</Box>

            <Box style={{ textAlign: "center", marginTop: 20 }}>
                <Button variant="contained" className="BlogButton" style={{ marginTop: 70 }} onClick={Submit}>Post Blog</Button>
            </Box>
            <ToastContainer />
        </Box>
    </>
    )
}




























// import React, { useState, useEffect } from 'react';
// // import ReactQuill from 'react-quill';
// // import 'react-quill/dist/quill.snow.css';
// import { Box, Button } from "@mui/material"
// import { PostBlogData } from './Services/Api';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { imageUploader } from './Services/Api';
// import AddIcon from '@mui/icons-material/Add';
// import ReactQuill, { Quill } from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import ImageResize from 'quill-image-resize-module-react';



// Quill.register('modules/imageResize', ImageResize);

// const Data = {
//     contents: "",
//     tittle: "",
//     description: "",
//     image: ""
// }

// var toolbarOptions = [
//     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
//     [{ 'font': [] }],
//     ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//     [],

//     ['code-block', 'link', 'image', 'video'],           // custom button values
//     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//     [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
//     [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
//     [{ 'direction': 'rtl' }],                         // text direction


//     [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

//     [{ 'align': [] }],


//     ['clean']                                         // remove formatting button
// ];
// var module = {
//     toolbar: toolbarOptions
// }






// const modules = {
//     toolbar: [
//         [{ 'header': [1, 2, 3, 4, 5, 6] }],[ { font: [] }],
//       [{ size: [] }],
//       ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//       [
//         { list: 'ordered' },
//         { list: 'bullet' },
//         { indent: '-1' },
//         { indent: '+1' }
//       ],
//       ['link', 'image', 'video'],
//       ['clean']
//     ],
//     clipboard: {
//       // toggle to add extra line breaks when pasting HTML:
//       matchVisual: false
//     },
//     imageResize: {
//       parchment: Quill.import('parchment'),
//       modules: ['Resize', 'DisplaySize']
//     }
//   };

//  const formats = [
//     'header',
//     'font',
//     'size',
//     'bold',
//     'italic',
//     'underline',
//     'strike',
//     'blockquote',
//     'list',
//     'bullet',
//     'indent',
//     'link',
//     'image',
//     'video'
//   ];
// export const Blog_post = () => {
    
//     const [content, setContent] = useState(Data);
//     const [file, setFile] = useState("");
//     const [image, setImage] = useState("");
//     const [images, setImages] = useState("");
//     const SaveBlogData = (event) => {
//         setContent({ ...content, [event.target.name]: event.target.value });

//     }
//     const SaveEditorData = (newContent) => {
//         setContent({ ...content, contents: newContent });
//         console.log(content)
//     }

//     const Submit = async () => {
//         if (!content.tittle) {
//             toast.error("Please Enter tittle")
//         }
//         else if (!content.image) {
//             toast.error("Please Enter Thumbnail Url")
//         }
//         else if (!content.description) {
//             toast.error("Please Enter description ")
//         }
//         else if (!content.contents) {
//             toast.error("Please Enter Somtheing aboout your blog ")
//         }
//         else {
//             const response = await PostBlogData(content);
//             if (response.status == 200) {
//                 toast.success("Your Blog  Successfull Post");
//                 content.contents = "";
//                 content.tittle = "";
//                 content.image = "";
//                 setFile(null);
//                 content.description = "";

//             }
//         }
//     }
//     return (<>
//         <Box className="editPost">
//             <input type="Textarea" placeholder="Tittle" name="tittle" value={content.tittle} onChange={SaveBlogData} />
//             <Box className="Thumbnail">
//                 <Box style={{ width: "100%" }}>
//                     <input type='text' placeholder='Select Thumbnail' readOnly value={file ? file.name : ""} style={{ color: "#757575", fontWeight: 600, fontSize: 16 }} />
//                 </Box>
//                 <Box className="Thumbnail-Lable">
//                     <label htmlFor='thumbnail'>

//                         <AddIcon fontSize="medium" color="action" style={{ border: "1px solid black", background: "#18AD96", borderRadius: "50%", }} />
//                     </label>
//                 </Box>

//             </Box>

//             <input type="file" accept="image/*" placeholder="Thumbnail Url " id="thumbnail" onChange={(e) => SaveBlogData} style={{ display: "none" }} />
//             <textarea rows={7} cols={50} placeholder="Describe some Words About Your Blog at least 30 words" name="description" value={content.description} onChange={SaveBlogData} />



//             {/* <ReactQuill theme="snow" className="editior" value={content.contents} modules={module} onChange={setValue => SaveEditorData(setValue)} />; */}
// <Box style={{height:"40vh"}}>
//             <ReactQuill
//             style={{height:"100%" , }}
//         theme="snow"
//         onChange={setValue => SaveEditorData(setValue)}
//         modules={modules}
//         formats={formats}
//       />
// </Box>

//             <Box style={{ textAlign: "center", marginTop: 20 }}>
//                 <Button variant="contained" className="BlogButton" style={{ marginTop: 70 }} onClick={Submit}>Post Blog</Button>
//             </Box>
//             <ToastContainer />
//         </Box>
//     </>
//     )
// }

