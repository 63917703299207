import { Box, Button, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { AdminCheck } from "../../Services/Api";
import axios from "axios";

var Data = {
    name: "",
    password: ""
}

export const AdminLogin = () => {
    const [UserData, setData] = useState(Data);
    const Navigate = useNavigate();

    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });

    }

    const Submit = async () => {
        if (!UserData.name) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.password) {
            toast.error("Please Enter Password")
        }
        else {
            const Data = new FormData();
            Data.append('User_name', UserData.name);
            Data.append('Password', UserData.password);
            const Response = await AdminCheck(Data);
            if (Response.status == 200) {
                const login = localStorage.setItem("login", "DmknkjsSANKJCK1828w8u1wMAN");
                toast.success("Your succesffull Login");
                Navigate("/adminDashboard");
                window.location.reload()
            } else {
                toast.error("User Name And Password Are Incorrect");
                setTimeout(() => {
                    window.location.reload()
                }, 4500)
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Box style={{ marginBottom: 100, marginTop: 180 }}>
            <Box>
                <Box>
                    <Box className="BookAppointment-Box">
                        <h2>Login As Admin</h2>
                        <p>This Panel Is only Use For Admin Please Local User can't try to Login</p>
                        <Grid container spacing={5}>
                            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                                <Box>
                                    <input type="text" name='name' placeholder=" User Name : " className="contact-input" onChange={Book} ></input>
                                </Box>
                            </Grid>

                            <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                                <Box>
                                    <input type="text" name='password' placeholder="Password :" className="contact-input" onChange={Book}></input>
                                </Box>
                            </Grid>

                        </Grid>

                        <Button className="Book-appointment-btn" onClick={Submit} style={{ marginTop: 20 }}>Login</Button>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
        </Box>
    )
}