import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from "moment"
import { DataGrid } from '@mui/x-data-grid';
import { TodayAppointments } from "../../Services/Api";
import { Box, Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';

const columns = [
    { field: 'id2', headerName: 'ID', width: 70 },
    { field: 'Name', headerName: 'name', width: 130 },
    { field: 'Email', headerName: 'email', width: 130 },
    {
        field: 'Mobile',
        headerName: 'Phone Number',
        type: 'number',
        width: 130,
    },
    {
        field: 'Subject',
        headerName: 'Subject',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
    },
    {
        field: 'Message',
        headerName: 'Description',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,

    }
    ,
    {
        field: 'Date',
        headerName: 'Date',

        sortable: true,
        width: 160,

    }

    ,
    {
        field: 'Time',
        headerName: 'Time',
        sortable: true,
        width: 160,

    }
];

export default function TodayAppointmentData() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [todayAppointement, setTodayAppointement] = useState([]);
    const Date = {
        Date: moment().format('MMMM Do YYYY')
    }

    useEffect(() => {
        const AppointmentData = async () => {
            const Data = new FormData();
            Data.append('Date', Date.Date);
            const TodayAppointment = await TodayAppointments(Data);
            const fetchedData = TodayAppointment.data.reverse();
            if (fetchedData) {
                const dataWithId = fetchedData.map((item, index) => ({
                    id2: index + 1, // Generate a unique ID (using index here for simplicity)
                    ...item
                }));
                setTodayAppointement(dataWithId.reverse());

            }
        }
        AppointmentData()
    }, [])
    todayAppointement.reverse()
    return (
        <div className="Table-container">


            <DataGrid
                style={{ height: '90vh', margin: '150px 20px 20px 20px !important' }}
                rows={todayAppointement}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                checkboxSelection
            />


        </div>
    );
}